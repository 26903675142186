import React from 'react';
import { Link, Route } from 'react-router-dom';
import styled from 'styled-components';
import RecommendItems from './RecommendItems';
import useRecommend from './useRecommend';

const Recommend = (props) => {
   const { match } = props
   const recommend = useRecommend()
   return (
      <RECOMMEND>
         <Route exact path={match.path}>
            <div>
               <TITLE>추천명소</TITLE>
               <LIST>
               {recommend.items.map((r, rdex) => 
                  <Link to={`${match.path}/${rdex}`} key={rdex}>
                  <li>
                     <div>
                        <img src={r.thumbnail} alt={r.title}
                           width={'100%'} height={'100%'}
                        />
                     </div>
                     <h2>{r.title}</h2>
                  </li>
                  </Link>
               )}
               </LIST>
            </div>
         </Route>
         <Route path={`${match.path}/:id`}>
            <RecommendItems recommend={recommend} />
         </Route>
      </RECOMMEND>
   );
};

export default React.memo(Recommend);

const RECOMMEND = styled.section`
   position: absolute;
   width: 100%; height: 100%; max-height: 100%;
   padding: 80px 0px 0px 0px;
   background-color: #fefefe;
   &>div {
      width: 100%; height: 100%;
      overflow: scroll;
   }
`
const TITLE = styled.h1`
   font-size: 32px; color: #424242;
   padding: 10px 24px;
`

const LIST = styled.ul`
   position: relative;
   display: flex; flex-wrap: wrap; justify-content: center;
   padding: 10px 0px;
   &>a {
      &>li {
         width: 168px; height: 175px;
         padding: 5px;
         &>h2 {
            font-size: 12px; color: #444444;
            padding: 5px 0px;
         }
         &>div {
            width: 100%; height: 144px;
            background-color: #d8d8d8;
         }
      }
   }
`