import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import SVG from './SVG';

const Main = () => {
   const cloudRef = useRef(null)
   const hillRef = useRef(null)
   useEffect(() => {
      if (hillRef.current !== null && cloudRef.current !== null) {
         const cloud = cloudRef.current
         const hill = hillRef.current
         const hillpos = hill.getBoundingClientRect()
         const repos = function() {
            cloud.style.bottom = `${hillpos.height + 70}px`
            cloud.style.right = `${hillpos.right / 10}px`
         }
         repos()
      }
   }, [])

   return (
      <MAIN>
         <ARTICLE titles>
            <span>역사적 의미와 탁월한 경관을 조망할 수 있는</span>
            <h1>아라가야<br />역사순례길을<br />소개합니다.</h1>
         </ARTICLE>
         <ARTICLE link>
            <ul>
               <li className={'guidelink'}>
                  <Link to={'/guide'}>구간안내 바로가기</Link>
               </li>
            </ul>
         </ARTICLE>
         <SVG types={'mainbg'} />
      </MAIN>
   );
};

export default React.memo(Main);
const MAIN = styled.main`
   display: flex; flex-direction: column;
   justify-content: space-between;
   width: 100%; max-height: calc(100% - 60px);
   overflow: hidden scroll;
`
const ARTICLE = styled.article`
${props => props.titles && css`
   display: flex; flex-direction: column;
   padding: 10px 24px;
   z-index: 1;
   &>span {
      font-size: 13px; font-weight: 500;
      letter-spacing: -0.07px;
      color: #444444;
      padding: 0px 0px 5px 0px;
   }
   &>h1 {
      font-size: 32px; font-weight: 800;
      line-height: 1.31; letter-spacing: 0.03px;
      color: #424242;
   }
`}
${props => props.link && css`
   padding: 20px 24px;
   z-index: 1;
   &>ul {
      display: flex;
      .guidelink {
         &>a {
            flex: 0 auto;
            font-size: 18px; font-weight: 500;
            letter-spacing: 0.02px;
            color: #052b24;
            border-bottom: 1px solid #052b24;
            cursor: pointer;
         }
      }
      .cloud {
         flex: 1;
         display: flex; justify-content: flex-end;
      }
   }
`}
${props => props.recommend && css`
   padding: 20px 38px;
   z-index: 1;
   &>h1 {
      font-size: 18px; font-weight: 500;
      letter-spacing: 0.02px;
      color: #052b24;
   }
   &>div {
      position: relative;
      width: 100%; height: 220px;
      padding: 15px 0px;
      .swiper-container {
         width: 100%; height: 100%;
      }
      .swiper-slide {
         display: flex; justify-content: center;
         &>div {
            width: 216px; height: 156px;
         }
      }
      .swiper-pagination {
         bottom: 0px;
         &>span {
            margin: 0 10px;
         }
      }
   }
`}
${props => props.background && css`
   position: fixed; bottom: 0; left: 0;
   width: 100%; height: 100%;
   .cloud {
      position: absolute; bottom: 200px; right: 30%;
      display: flex; align-items: flex-start;
      width: 25%;
      &>svg {
         width: 100%; height: 100%;
      }
   }
   .hill {
      position: absolute; bottom: 0;
      width: 100%;
      &>svg {
         width: 100%; height: 100%; transform: translateY(1px);
      }
   }
   .man {
      position: absolute; bottom: 10px; left: 40px;
      width: 10%;
      &>svg {
         width: 100%; height: 100%;
      }
   }
`}
`