export default function useRecommend() {
   const slideImages = function(section, number) { 
      const address = `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/recommend/slide/`
      let arr = []
      for (let i = 1; i <= number; i++) {
         arr.push(
            address + `${section}_0${i}.jpg`
         )
      }
      return arr
   }
   const items = [
      {
         id: 1,
         thumbnail: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/recommend/thumbnail/01.jpg`,
         title: '말이산고분군',
         label: '사적 제515호',
         address: '함안군 가야읍 도향리 748',
         explain: '유네스코 세계유산 등재를 추진 중인 아라가야 시대의 고분군으로 개마무사의 말 갑옷과 별자리 덮개돌, 다양한 형태의 토기 등이 출토되었다. 모바일 앱을 통해 고분군 탐방안내를 받을 수 있으며, 함안박물관과 고분전시관에서 고분의 역사와 출토유물 등을 관람할 수 있다.',
         tag: [{name: '5구간', style: {color: '#fff', backgroundColor: '#3965ae'}}],
         slideImage: slideImages(`01`, 5),
         bg: (
            <svg width="75" height="47" viewBox="0 0 75 47">
               <defs>
                  <path id="a19r1qd3oa" d="M0.631 0.867L61.518 0.867 61.518 19 0.631 19z" />
               </defs>
               <g fill="none" fillRule="evenodd">
                  <g>
                     <g>
                        <g transform="translate(-276 -588) translate(276 588) translate(13 27.346)">
                           <mask id="vx4of6ip1b" fill="#fff">
                              <use xlinkHref="#a19r1qd3oa" />
                           </mask>
                           <path fill="#DCDC8E" d="M3.163 19c-2.257 0-3.386-2.74-1.77-4.32C6.881 9.317 17.557.867 30.34.867c12.713 0 24.237 8.36 30.324 13.727 1.74 1.533.642 4.406-1.678 4.406H3.163z" mask="url(#vx4of6ip1b)" />
                        </g>
                        <path fill="#DCDC8E" d="M1.543 11.048c-1.376 0-2.064-1.67-1.08-2.632C3.808 5.149 10.313 0 18.101 0c7.746 0 14.767 5.093 18.476 8.363 1.061.935.391 2.685-1.022 2.685H1.543z" transform="translate(-276 -588) translate(276 588)" />
                        <path fill="#D4D080" d="M8.467 15.37c-1.537 0-2.305-1.864-1.206-2.938 3.735-3.65 11.001-9.401 19.7-9.401 8.652 0 16.493 5.689 20.637 9.34 1.183 1.045.435 3-1.143 3H8.467z" transform="translate(-276 -588) translate(276 588)" />
                        <path fill="#DCDC8E" d="M3.72 26.114c-1.91 0-2.864-2.318-1.498-3.653 4.642-4.536 13.671-11.682 24.48-11.682 10.752 0 20.497 7.07 25.647 11.608 1.471 1.298.541 3.727-1.42 3.727H3.72z" transform="translate(-276 -588) translate(276 588)" />
                        <path fill="#D4D080" d="M10.654 35.298c-1.695 0-2.543-2.058-1.33-3.243 4.12-4.027 12.136-10.37 21.732-10.37 9.545 0 18.196 6.276 22.767 10.305 1.306 1.15.48 3.308-1.261 3.308H10.654z" transform="translate(-276 -588) translate(276 588)" />
                     </g>
                  </g>
               </g>
            </svg>
         ),
      },
      {
         id: 2,
         thumbnail: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/recommend/thumbnail/02.jpg`,
         title: '성산산성',
         label: '사적 제67호',
         address: '함안군 가야읍 광정리 13',
         explain: '삼국시대에 쌓은 산성이다. 목간의 보고로 우리나라에서 가장 오래된 이두문장이 출토됐으며 아라홍련 연씨가 발굴된 곳이기도 하다. 여항산과 말이산고분군을 조망하는 경치가 일품이다.',
         tag: [{name: '4구간', style: {color: '#fff', backgroundColor: '#fad05d'}}],
         slideImage: slideImages(`02`, 2),
         bg: (
            <svg width="99" height="77" viewBox="0 0 99 77">
               <defs>
                  <path id="9ia8vbutba" d="M0.707 0.786L75.439 0.786 75.439 77 0.707 77z" />
                  <path id="wk4laa1njc" d="M0.279 0.631L8.856 0.631 8.856 8.671 0.279 8.671z" />
                  <path id="ctd8teenhe" d="M0 0.537L2.552 0.537 2.552 2.266 0 2.266z" />
                  <path id="9zsuora7rg" d="M0 77.214L98.856 77.214 98.856 1 0 1z" />
               </defs>
               <g fill="none" fillRule="evenodd">
                  <g>
                     <g transform="translate(-256 -570) translate(256 569)">
                        <g transform="translate(13 .214)">
                           <mask id="dkdf7ks64b" fill="#fff">
                              <use xlinkHref="#9ia8vbutba" />
                           </mask>
                           <path fill="#A4BEA3" d="M58.55 13.017c-.78-1.684-1.792-3.285-3.046-4.76-7.896-9.284-22.286-10.015-32.14-1.635-3.971 3.378-6.597 7.722-7.803 12.311-2.216.95-4.341 2.243-6.287 3.9-9.854 8.38-11.44 22.701-3.544 31.984 1.198 1.41 2.548 2.617 4.006 3.63.499 3.997 2.096 7.847 4.849 11.085 6.738 7.922 18.202 9.612 27.543 4.731 7.773 3.493 17.49 2.271 24.748-3.903 9.462-8.048 11.296-21.57 4.435-30.852 2.084-6.999.832-14.668-4.052-20.41-2.404-2.826-5.412-4.855-8.708-6.08" mask="url(#dkdf7ks64b)" />
                        </g>
                        <path fill="#A7CEAC" d="M66.838 15.856c-.641-1.383-1.473-2.698-2.502-3.908-6.484-7.623-18.3-8.225-26.391-1.343-3.261 2.773-5.417 6.341-6.407 10.108-1.82.78-3.565 1.842-5.163 3.203-8.092 6.881-9.394 18.64-2.91 26.263.984 1.156 2.092 2.15 3.289 2.981.41 3.282 1.72 6.444 3.982 9.102 5.533 6.505 14.946 7.893 22.616 3.885 6.382 2.868 14.362 1.864 20.322-3.205 7.769-6.608 9.275-17.71 3.642-25.334 1.71-5.746.683-12.044-3.328-16.759-1.974-2.32-4.443-3.987-7.15-4.993" />
                        <path fill="#ACBE96" d="M57.721 17.513c-.432-.932-.992-1.818-1.686-2.633-4.368-5.136-12.328-5.54-17.778-.905-2.197 1.87-3.65 4.271-4.317 6.81-1.226.525-2.402 1.241-3.478 2.157-5.45 4.636-6.328 12.558-1.96 17.694.662.778 1.408 1.447 2.215 2.008.276 2.211 1.16 4.341 2.683 6.132 3.727 4.382 10.07 5.316 15.236 2.617 4.3 1.932 9.675 1.256 13.69-2.159 5.234-4.452 6.25-11.932 2.454-17.067 1.152-3.872.46-8.114-2.242-11.29-1.33-1.564-2.993-2.686-4.817-3.364" />
                        <g transform="translate(90 40.214)">
                           <mask id="mmogo59ird" fill="#fff">
                              <use xlinkHref="#wk4laa1njc" />
                           </mask>
                           <path fill="#80B3E1" d="M1.911.975L.501 2.586S.2 3.19.301 3.794C.4 4.4.5 6.01.803 6.816c.301.805.503 1.611 1.006 1.713.504.1 1.21.201 2.216.1 1.008-.1 1.21.101 2.216 0 1.007-.1 1.41-.302 1.511-.705.1-.403.805-2.619.805-3.324 0-.705.504-2.417.202-2.92-.302-.504-.302-.807-1.712-.907-1.41-.1-2.72-.2-3.525-.1-.806.1-1.612.302-1.612.302" mask="url(#mmogo59ird)" />
                        </g>
                        <path fill="#EB7745" d="M31.465 11.377L30.147 13.048 32.699 13.106z" />
                        <path fill="#B46E19" d="M31.465 11.377L33.748 10.606 35.015 12.242 32.699 13.106z" />
                        <path fill="#E6D9CB" d="M30.223 13.039L30.223 14.433 32.69 14.424 32.69 13.09z" />
                        <path fill="#C0B6A4" d="M32.699 13.106L32.699 14.423 34.948 13.45 34.94 12.275z" />
                        <g transform="translate(0 21.214)">
                           <mask id="uuleev850f" fill="#fff">
                              <use xlinkHref="#ctd8teenhe" />
                           </mask>
                           <path fill="#EB7745" d="M1.318 0.537L0 2.207 2.552 2.266z" mask="url(#uuleev850f)" />
                        </g>
                        <path fill="#B46E19" d="M1.318 21.751L3.601 20.979 4.868 22.615 2.552 23.48z" />
                        <path fill="#E6D9CB" d="M0.076 23.413L0.076 24.806 2.543 24.798 2.543 23.463z" />
                        <path fill="#C0B6A4" d="M2.552 23.479L2.552 24.797 4.801 23.824 4.793 22.649z" />
                        <path fill="#D24A72" d="M27.118 10.32L25.985 11.881 28.075 11.855z" />
                        <path fill="#C04869" d="M27.118 10.32L27.621 10.101 28.528 11.579 28.075 11.855z" />
                        <path fill="#E4C2CD" d="M26.127 11.864L26.093 13.106 28.074 13.106 28.074 11.855z" />
                        <path fill="#C3A4B0" d="M28.075 11.864L28.092 13.123 28.494 12.922 28.478 11.595z" />
                        <path fill="#D24A72" d="M27.835 13.925L26.413 15.694 29.037 15.665z" />
                        <path fill="#C04869" d="M27.835 13.925L28.467 13.677 29.606 15.351 29.037 15.665z" />
                        <path fill="#E4C2CD" d="M26.592 15.675L26.55 17.081 29.037 17.081 29.037 15.665z" />
                        <path fill="#C3A4B0" d="M29.037 15.675L29.058 17.102 29.563 16.872 29.542 15.37z" />
                        <path fill="#E77945" d="M17.932 21.042L16.509 22.811 19.133 22.782z" />
                        <path fill="#B36F18" d="M17.932 21.042L18.564 20.794 19.702 22.469 19.133 22.782z" />
                        <path fill="#E6D9CB" d="M16.688 22.792L16.646 24.199 19.133 24.199 19.133 22.782z" />
                        <path fill="#C3B3A6" d="M19.133 22.792L19.154 24.219 19.66 23.99 19.639 22.487z" />
                        <path fill="#00A357" d="M5.056 21.816c0 .286.266.52.595.52.328 0 .594-.234.594-.52 0-.286-.266-.519-.594-.519-.33 0-.595.233-.595.52M2.784 21.74c0 .406.378.736.846.736.466 0 .845-.33.845-.736 0-.407-.38-.737-.845-.737-.468 0-.846.33-.846.737" />
                        <path fill="#00A357" d="M3.445 20.231c0 .612.569 1.108 1.272 1.108.703 0 1.272-.496 1.272-1.108 0-.61-.57-1.107-1.272-1.107-.703 0-1.272.496-1.272 1.107" />
                        <mask id="wieubk8gph" fill="#fff">
                           <use xlinkHref="#9zsuora7rg" />
                        </mask>
                        <path fill="#366D93" d="M4.672 24.175L4.762 24.175 4.762 20.683 4.672 20.683z" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M4.723 22.57c-.012 0-.024-.003-.034-.01l-.879-.606c-.023-.017-.027-.046-.008-.067.018-.02.052-.024.076-.007l.843.582.766-.581c.023-.017.057-.015.076.004.02.02.018.05-.005.067l-.8.606c-.01.008-.022.012-.035.012" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M30.377 9.973c0 .286.267.52.595.52.328 0 .595-.234.595-.52 0-.286-.267-.518-.595-.518-.328 0-.595.232-.595.518M28.105 9.897c0 .407.379.736.846.736.467 0 .845-.329.845-.736s-.378-.736-.845-.736c-.467 0-.846.33-.846.736" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M28.767 8.39c0 .61.569 1.106 1.271 1.106.703 0 1.273-.496 1.273-1.107 0-.612-.57-1.108-1.273-1.108s-1.271.496-1.271 1.108" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M29.993 12.332L30.083 12.332 30.083 8.84 29.993 8.84z" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M30.044 10.727c-.012 0-.024-.003-.034-.01l-.878-.606c-.024-.016-.027-.046-.009-.067.02-.02.053-.023.076-.007l.844.582.765-.58c.023-.018.057-.016.077.003.02.021.017.05-.006.068l-.799.605c-.01.008-.023.012-.036.012" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M26.214 21.455c0 .286.266.519.595.519.328 0 .594-.233.594-.52 0-.285-.266-.517-.594-.517-.329 0-.595.232-.595.518M23.942 21.379c0 .407.379.737.846.737.467 0 .846-.33.846-.737 0-.406-.38-.736-.846-.736-.467 0-.846.33-.846.736" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M24.604 19.87c0 .613.569 1.109 1.271 1.109.703 0 1.273-.496 1.273-1.108 0-.612-.57-1.108-1.273-1.108s-1.271.496-1.271 1.108" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M25.83 23.814L25.921 23.814 25.921 20.321 25.83 20.321z" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M25.881 22.21c-.012 0-.023-.004-.034-.011l-.878-.606c-.024-.016-.027-.046-.009-.067.02-.02.053-.023.076-.007l.844.582.765-.58c.023-.018.057-.016.077.004.02.02.017.049-.006.067l-.799.606c-.01.008-.023.012-.036.012" mask="url(#wieubk8gph)" />
                        <path fill="#27926C" d="M95.838 47.851c.026-.076.046-.155.051-.239.03-.505-.355-.939-.86-.968-.474-.028-.88.311-.954.77h-.007c-.504-.03-.938.355-.967.86-.03.504.355.937.86.967.228.013.44-.06.608-.19.142.281.423.486.761.505.505.03.938-.355.968-.859.021-.359-.169-.678-.46-.846" mask="url(#wieubk8gph)" />
                        <path fill="#27926C" d="M97.953 45.66c.026-.075.046-.154.051-.238.03-.505-.355-.94-.86-.968-.474-.028-.88.31-.953.77h-.008c-.504-.031-.937.354-.967.859-.03.505.355.938.86.968.228.013.44-.061.608-.191.142.282.423.486.761.506.505.029.938-.355.968-.86.021-.358-.168-.677-.46-.845" mask="url(#wieubk8gph)" />
                        <path fill="#27926C" d="M98.28 43.748c.027-.076.047-.156.052-.24.03-.505-.355-.938-.86-.968-.474-.028-.881.312-.954.77h-.007c-.504-.03-.938.355-.967.86-.031.504.355.937.859.968.229.013.44-.062.609-.191.142.282.423.486.76.505.506.03.939-.354.969-.86.02-.358-.17-.677-.46-.844" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M91.623 35.204c0 .405.377.733.843.733.465 0 .843-.328.843-.733 0-.405-.378-.734-.843-.734-.466 0-.843.329-.843.734M88.404 35.095c0 .576.537 1.044 1.198 1.044.662 0 1.198-.468 1.198-1.044 0-.576-.536-1.044-1.198-1.044-.66 0-1.198.468-1.198 1.044" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M89.341 32.959c0 .867.807 1.57 1.802 1.57s1.802-.703 1.802-1.57c0-.866-.807-1.57-1.802-1.57s-1.802.704-1.802 1.57" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M91.079 38.545L91.207 38.545 91.207 33.598 91.079 33.598z" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M91.152 36.272c-.017 0-.034-.005-.048-.015l-1.244-.859c-.034-.022-.04-.064-.013-.094.027-.029.075-.034.108-.01l1.195.824 1.085-.823c.03-.024.08-.02.108.007.028.028.024.071-.007.094l-1.134.86c-.014.01-.032.016-.05.016" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M96.877 37.1c0 .405.377.734.843.734.465 0 .843-.329.843-.734 0-.405-.378-.734-.843-.734-.466 0-.843.33-.843.734M93.658 36.992c0 .577.537 1.044 1.198 1.044.662 0 1.198-.467 1.198-1.044 0-.576-.536-1.043-1.198-1.043-.66 0-1.198.467-1.198 1.043" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M94.595 34.856c0 .867.807 1.57 1.802 1.57s1.802-.703 1.802-1.57c0-.867-.807-1.57-1.802-1.57s-1.802.703-1.802 1.57" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M96.333 40.441L96.461 40.441 96.461 35.495 96.333 35.495z" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M96.406 38.169c-.017 0-.034-.005-.048-.015l-1.244-.858c-.033-.023-.04-.065-.013-.095.027-.028.075-.033.108-.011l1.195.825 1.085-.823c.032-.024.08-.021.108.007.028.029.024.07-.007.095l-1.134.858c-.014.01-.032.017-.05.017" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M93.402 38.057c0 .405.377.734.843.734.465 0 .843-.329.843-.734 0-.405-.378-.734-.843-.734-.466 0-.843.33-.843.734M90.183 37.949c0 .576.537 1.044 1.198 1.044.662 0 1.198-.468 1.198-1.044 0-.576-.535-1.044-1.197-1.044s-1.199.468-1.199 1.044" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M91.12 35.813c0 .866.807 1.57 1.802 1.57.996 0 1.803-.704 1.803-1.57 0-.867-.807-1.57-1.802-1.57-.996 0-1.802.703-1.802 1.57" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M92.859 41.398L92.987 41.398 92.987 36.451 92.859 36.451z" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M92.931 39.126c-.017 0-.034-.005-.048-.015l-1.244-.858c-.034-.023-.039-.065-.013-.095.027-.028.075-.034.108-.011l1.195.825 1.085-.823c.031-.024.08-.021.108.007.028.028.024.07-.007.095l-1.134.858c-.014.01-.032.017-.05.017" mask="url(#wieubk8gph)" />
                     </g>
                  </g>
               </g>
            </svg>
         ),
      },
      {
         id: 3,
         thumbnail: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/recommend/thumbnail/03.jpg`,
         title: '남문외고분군',
         label: '도 기념물 제226호',
         address: '함안군 가야읍 가야리 107-1',
         explain: '말이산고분군에 이어 조성된 아라가야 시대의 고분군으로 2019년 발굴조사에서 우리나라에서 가장 큰 횡혈식석실(6호분)이 확인됐다. 사적 승격을 추진 중이다.',
         tag: [{name: '7구간', style: {color: '#fff', backgroundColor: '#ea4c78'}}],
         slideImage: slideImages(`03`, 2),
         bg: (
            <svg width="101" height="53" viewBox="0 0 101 53">
               <defs>
                  <path id="d0vr4ld5la" d="M0.645 0.66L42.079 0.66 42.079 13 0.645 13z" />
               </defs>
               <g fill="none" fillRule="evenodd">
                  <g>
                     <g>
                        <path fill="#DCDC8D" d="M2.533 18.133c-2.258 0-3.388-2.74-1.772-4.319C6.25 8.45 16.926 0 29.71 0c12.713 0 24.237 8.36 30.324 13.727 1.74 1.534.642 4.406-1.678 4.406H2.532z" transform="translate(-258 -588) translate(258 588)" />
                        <g transform="translate(-258 -588) translate(258 588) translate(58 39.422)">
                           <mask id="3mkxqojnjb" fill="#fff">
                              <use xlinkHref="#d0vr4ld5la" />
                           </mask>
                           <path fill="#D3CF80" d="M2.368 13c-1.537 0-2.305-1.866-1.206-2.94 3.735-3.65 11.001-9.4 19.7-9.4 8.652 0 16.493 5.69 20.636 9.341 1.184 1.045.436 3-1.142 3H2.368z" mask="url(#3mkxqojnjb)" />
                        </g>
                     </g>
                  </g>
               </g>
            </svg>
         ),
      },
      {
         id: 4,
         thumbnail: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/recommend/thumbnail/04.png`,
         title: '연꽃테마파크',
         label: '',
         address: '함안군 가야읍 가야리 233-1',
         explain: '유네스코 세계유산 등재를 추진 중인 아라가야 시대의 고분군으로 개마무사의 말 갑옷과 별자리 덮개돌, 다양한 형태의 토기 등이 출토되었다. 모바일 앱을 통해 고분군 탐방안내를 받을 수 있으며, 함안박물관과 고분전시관에서 고분의 역사와 출토유물 등을 관람할 수 있다.',
         tag: [{name: '7구간', style: {color: '#fff', backgroundColor: '#ea4c78'}}],
         slideImage: slideImages(`04`, 5),
         bg: (
            <svg width="111" height="61" viewBox="0 0 111 61">
               <defs>
                  <path id="coaeiujw0a" d="M0 0.587L38.719 0.587 38.719 18.81 0 18.81z" />
                  <path id="7ra63jvmrc" d="M0 61L111 61 111 0 0 0z" />
               </defs>
               <g fill="none" fillRule="evenodd">
                  <g>
                     <g transform="translate(-240 -581) translate(240 581)">
                        <g transform="translate(0 30)">
                           <mask id="8hhwlsl06b" fill="#fff">
                              <use xlinkHref="#coaeiujw0a" />
                           </mask>
                           <path fill="#BAB46B" d="M37.772 18.81c.786 0 1.291-1.893.668-2.767C35.6 12.062 33.368 3.18 27.928 1.408 22.196-.462 13.836.873 8.59 5.314 5.228 8.16 2.104 12.906.293 15.94c-.608 1.021-.211 2.869.613 2.869h36.866z" mask="url(#8hhwlsl06b)" />
                        </g>
                        <path fill="#D3CF80" d="M108.528 53c2.057 0 3.37-2.598 1.736-3.802-7.395-5.456-13.223-17.635-27.42-20.073-14.948-2.565-36.75-.73-50.434 5.358-8.772 3.908-16.922 10.412-21.64 14.58C9.184 50.465 10.21 53 12.366 53h96.162z" />
                        <path fill="#E4DE6A" d="M104.046 61c.792 0 1.3-1.87.672-2.733-2.855-3.933-5.108-12.706-10.587-14.456-5.774-1.847-14.194-.529-19.478 3.858-3.386 2.811-6.532 7.5-8.356 10.497-.613 1.01-.213 2.834.617 2.834h37.132z" />
                        <path fill="#DB7EB0" d="M75.9 2s.99 12.094-3.259 21.454c-4.932 10.851-11.976 15.34-14.336 14.432-2.275-.87-1.733-9.673 2.883-20.065C66.143 6.665 75.9 2 75.9 2" />
                        <path fill="#C65C97" d="M90 13s-3.349 11.31-10.724 18.315c-8.556 8.13-16.87 9.75-18.8 8.111-1.858-1.583 1.782-9.373 9.877-17.195C79.037 13.839 90 13 90 13" />
                        <path fill="#D2448F" d="M45.197 2s10.449 5.928 15.715 14.71c6.11 10.19 5.823 18.594 3.755 20.065-1.99 1.418-8.883-3.954-14.792-13.626C43.535 12.775 45.197 2 45.197 2" />
                        <path fill="#C25E95" d="M32 15s11.86 1.62 19.93 7.642c9.36 6.984 12.124 14.63 10.73 16.685-1.34 1.974-9.693-.405-18.681-6.995C34.337 25.26 32 15 32 15" />
                        <path fill="#D069A3" d="M63.645 0s5.67 10.748 5.341 21.044C68.604 32.99 63.74 39.905 61.164 40c-2.48.094-5.412-8.222-5.147-19.617C56.3 8.154 63.645 0 63.645 0" />
                        <path fill="#56ADAA" d="M34 51c0 3.863-3.133 7-7 7s-7-3.137-7-7c0-3.867 3.133-7 7-7s7 3.133 7 7" />
                        <mask id="l44fm70ued" fill="#fff">
                           <use xlinkHref="#7ra63jvmrc" />
                        </mask>
                        <path fill="#366D93" d="M25.868 59L26.132 59 26.132 52 25.868 52z" mask="url(#l44fm70ued)" />
                        <path fill="#366D93" d="M25.978 57.048c-.032 0-.068-.016-.092-.04l-1.788-1.788c-.052-.052-.052-.136 0-.188.048-.052.132-.052.184 0l1.696 1.7 1.74-1.744c.052-.048.136-.048.184 0 .052.052.052.14 0 .188l-1.832 1.832c-.024.024-.06.04-.092.04M25.985 54.742c-.032 0-.067-.017-.091-.04l-1.192-1.192c-.053-.052-.053-.136 0-.184.047-.053.131-.053.183 0l1.1 1.1 1.128-1.129c.053-.051.137-.051.189 0 .047.049.047.133 0 .184l-1.224 1.22c-.024.024-.06.04-.093.04" mask="url(#l44fm70ued)" />
                        <path fill="#56ADAA" d="M21 49.002C21 52.864 17.867 56 14 56s-7-3.136-7-6.998C7 45.136 10.133 42 14 42s7 3.136 7 7.002" mask="url(#l44fm70ued)" />
                        <path fill="#366D93" d="M13.868 56L14.132 56 14.132 49 13.868 49z" mask="url(#l44fm70ued)" />
                        <path fill="#366D93" d="M13.976 55.046c-.032 0-.068-.012-.092-.036l-1.787-1.792c-.053-.052-.053-.132 0-.184.048-.052.132-.052.184 0l1.695 1.696 1.744-1.74c.048-.048.132-.048.18 0 .056.052.056.136 0 .184l-1.832 1.836c-.024.024-.06.036-.091.036M13.986 52.742c-.033 0-.069-.012-.093-.04l-1.192-1.192c-.052-.048-.052-.133 0-.184.048-.048.133-.048.184 0l1.1 1.1 1.129-1.129c.051-.051.135-.051.188 0 .048.049.048.137 0 .189l-1.224 1.216c-.024.028-.06.04-.092.04" mask="url(#l44fm70ued)" />
                     </g>
                  </g>
               </g>
            </svg>
         )
      },
      {
         id: 5,
         thumbnail: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/recommend/thumbnail/05.jpg`,
         title: '무진정',
         label: '도 유형문화재 제158호',
         address: '함안군 가야읍 광정리 13',
         explain: '독서를 좋아했던 무진 조삼 선생이 1542년 지은 조선 초기의 정자이다.  주세붕 선생이 쓴 무진정 현판과 기문이 유명하다. 정자 앞 연못 풍경이 어우러져 소박한 아름다움을 뽐내고, 한적한 경치를 즐기기 위한 이들의 발걸음이 끊이지 않는다. 도 무형문화재 제 33호인 함안낙화놀이가 매년 사월 초파일에 열린다.',
         tag: [{name: '4구간', style: {color: '#fff', backgroundColor: '#fad05d'}}],
         slideImage: slideImages(`05`, 5),
         bg: (
            <svg width="99" height="77" viewBox="0 0 99 77">
               <defs>
                  <path id="9ia8vbutba" d="M0.707 0.786L75.439 0.786 75.439 77 0.707 77z" />
                  <path id="wk4laa1njc" d="M0.279 0.631L8.856 0.631 8.856 8.671 0.279 8.671z" />
                  <path id="ctd8teenhe" d="M0 0.537L2.552 0.537 2.552 2.266 0 2.266z" />
                  <path id="9zsuora7rg" d="M0 77.214L98.856 77.214 98.856 1 0 1z" />
               </defs>
               <g fill="none" fillRule="evenodd">
                  <g>
                     <g transform="translate(-256 -570) translate(256 569)">
                        <g transform="translate(13 .214)">
                           <mask id="dkdf7ks64b" fill="#fff">
                              <use xlinkHref="#9ia8vbutba" />
                           </mask>
                           <path fill="#A4BEA3" d="M58.55 13.017c-.78-1.684-1.792-3.285-3.046-4.76-7.896-9.284-22.286-10.015-32.14-1.635-3.971 3.378-6.597 7.722-7.803 12.311-2.216.95-4.341 2.243-6.287 3.9-9.854 8.38-11.44 22.701-3.544 31.984 1.198 1.41 2.548 2.617 4.006 3.63.499 3.997 2.096 7.847 4.849 11.085 6.738 7.922 18.202 9.612 27.543 4.731 7.773 3.493 17.49 2.271 24.748-3.903 9.462-8.048 11.296-21.57 4.435-30.852 2.084-6.999.832-14.668-4.052-20.41-2.404-2.826-5.412-4.855-8.708-6.08" mask="url(#dkdf7ks64b)" />
                        </g>
                        <path fill="#A7CEAC" d="M66.838 15.856c-.641-1.383-1.473-2.698-2.502-3.908-6.484-7.623-18.3-8.225-26.391-1.343-3.261 2.773-5.417 6.341-6.407 10.108-1.82.78-3.565 1.842-5.163 3.203-8.092 6.881-9.394 18.64-2.91 26.263.984 1.156 2.092 2.15 3.289 2.981.41 3.282 1.72 6.444 3.982 9.102 5.533 6.505 14.946 7.893 22.616 3.885 6.382 2.868 14.362 1.864 20.322-3.205 7.769-6.608 9.275-17.71 3.642-25.334 1.71-5.746.683-12.044-3.328-16.759-1.974-2.32-4.443-3.987-7.15-4.993" />
                        <path fill="#ACBE96" d="M57.721 17.513c-.432-.932-.992-1.818-1.686-2.633-4.368-5.136-12.328-5.54-17.778-.905-2.197 1.87-3.65 4.271-4.317 6.81-1.226.525-2.402 1.241-3.478 2.157-5.45 4.636-6.328 12.558-1.96 17.694.662.778 1.408 1.447 2.215 2.008.276 2.211 1.16 4.341 2.683 6.132 3.727 4.382 10.07 5.316 15.236 2.617 4.3 1.932 9.675 1.256 13.69-2.159 5.234-4.452 6.25-11.932 2.454-17.067 1.152-3.872.46-8.114-2.242-11.29-1.33-1.564-2.993-2.686-4.817-3.364" />
                        <g transform="translate(90 40.214)">
                           <mask id="mmogo59ird" fill="#fff">
                              <use xlinkHref="#wk4laa1njc" />
                           </mask>
                           <path fill="#80B3E1" d="M1.911.975L.501 2.586S.2 3.19.301 3.794C.4 4.4.5 6.01.803 6.816c.301.805.503 1.611 1.006 1.713.504.1 1.21.201 2.216.1 1.008-.1 1.21.101 2.216 0 1.007-.1 1.41-.302 1.511-.705.1-.403.805-2.619.805-3.324 0-.705.504-2.417.202-2.92-.302-.504-.302-.807-1.712-.907-1.41-.1-2.72-.2-3.525-.1-.806.1-1.612.302-1.612.302" mask="url(#mmogo59ird)" />
                        </g>
                        <path fill="#EB7745" d="M31.465 11.377L30.147 13.048 32.699 13.106z" />
                        <path fill="#B46E19" d="M31.465 11.377L33.748 10.606 35.015 12.242 32.699 13.106z" />
                        <path fill="#E6D9CB" d="M30.223 13.039L30.223 14.433 32.69 14.424 32.69 13.09z" />
                        <path fill="#C0B6A4" d="M32.699 13.106L32.699 14.423 34.948 13.45 34.94 12.275z" />
                        <g transform="translate(0 21.214)">
                           <mask id="uuleev850f" fill="#fff">
                              <use xlinkHref="#ctd8teenhe" />
                           </mask>
                           <path fill="#EB7745" d="M1.318 0.537L0 2.207 2.552 2.266z" mask="url(#uuleev850f)" />
                        </g>
                        <path fill="#B46E19" d="M1.318 21.751L3.601 20.979 4.868 22.615 2.552 23.48z" />
                        <path fill="#E6D9CB" d="M0.076 23.413L0.076 24.806 2.543 24.798 2.543 23.463z" />
                        <path fill="#C0B6A4" d="M2.552 23.479L2.552 24.797 4.801 23.824 4.793 22.649z" />
                        <path fill="#D24A72" d="M27.118 10.32L25.985 11.881 28.075 11.855z" />
                        <path fill="#C04869" d="M27.118 10.32L27.621 10.101 28.528 11.579 28.075 11.855z" />
                        <path fill="#E4C2CD" d="M26.127 11.864L26.093 13.106 28.074 13.106 28.074 11.855z" />
                        <path fill="#C3A4B0" d="M28.075 11.864L28.092 13.123 28.494 12.922 28.478 11.595z" />
                        <path fill="#D24A72" d="M27.835 13.925L26.413 15.694 29.037 15.665z" />
                        <path fill="#C04869" d="M27.835 13.925L28.467 13.677 29.606 15.351 29.037 15.665z" />
                        <path fill="#E4C2CD" d="M26.592 15.675L26.55 17.081 29.037 17.081 29.037 15.665z" />
                        <path fill="#C3A4B0" d="M29.037 15.675L29.058 17.102 29.563 16.872 29.542 15.37z" />
                        <path fill="#E77945" d="M17.932 21.042L16.509 22.811 19.133 22.782z" />
                        <path fill="#B36F18" d="M17.932 21.042L18.564 20.794 19.702 22.469 19.133 22.782z" />
                        <path fill="#E6D9CB" d="M16.688 22.792L16.646 24.199 19.133 24.199 19.133 22.782z" />
                        <path fill="#C3B3A6" d="M19.133 22.792L19.154 24.219 19.66 23.99 19.639 22.487z" />
                        <path fill="#00A357" d="M5.056 21.816c0 .286.266.52.595.52.328 0 .594-.234.594-.52 0-.286-.266-.519-.594-.519-.33 0-.595.233-.595.52M2.784 21.74c0 .406.378.736.846.736.466 0 .845-.33.845-.736 0-.407-.38-.737-.845-.737-.468 0-.846.33-.846.737" />
                        <path fill="#00A357" d="M3.445 20.231c0 .612.569 1.108 1.272 1.108.703 0 1.272-.496 1.272-1.108 0-.61-.57-1.107-1.272-1.107-.703 0-1.272.496-1.272 1.107" />
                        <mask id="wieubk8gph" fill="#fff">
                           <use xlinkHref="#9zsuora7rg" />
                        </mask>
                        <path fill="#366D93" d="M4.672 24.175L4.762 24.175 4.762 20.683 4.672 20.683z" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M4.723 22.57c-.012 0-.024-.003-.034-.01l-.879-.606c-.023-.017-.027-.046-.008-.067.018-.02.052-.024.076-.007l.843.582.766-.581c.023-.017.057-.015.076.004.02.02.018.05-.005.067l-.8.606c-.01.008-.022.012-.035.012" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M30.377 9.973c0 .286.267.52.595.52.328 0 .595-.234.595-.52 0-.286-.267-.518-.595-.518-.328 0-.595.232-.595.518M28.105 9.897c0 .407.379.736.846.736.467 0 .845-.329.845-.736s-.378-.736-.845-.736c-.467 0-.846.33-.846.736" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M28.767 8.39c0 .61.569 1.106 1.271 1.106.703 0 1.273-.496 1.273-1.107 0-.612-.57-1.108-1.273-1.108s-1.271.496-1.271 1.108" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M29.993 12.332L30.083 12.332 30.083 8.84 29.993 8.84z" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M30.044 10.727c-.012 0-.024-.003-.034-.01l-.878-.606c-.024-.016-.027-.046-.009-.067.02-.02.053-.023.076-.007l.844.582.765-.58c.023-.018.057-.016.077.003.02.021.017.05-.006.068l-.799.605c-.01.008-.023.012-.036.012" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M26.214 21.455c0 .286.266.519.595.519.328 0 .594-.233.594-.52 0-.285-.266-.517-.594-.517-.329 0-.595.232-.595.518M23.942 21.379c0 .407.379.737.846.737.467 0 .846-.33.846-.737 0-.406-.38-.736-.846-.736-.467 0-.846.33-.846.736" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M24.604 19.87c0 .613.569 1.109 1.271 1.109.703 0 1.273-.496 1.273-1.108 0-.612-.57-1.108-1.273-1.108s-1.271.496-1.271 1.108" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M25.83 23.814L25.921 23.814 25.921 20.321 25.83 20.321z" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M25.881 22.21c-.012 0-.023-.004-.034-.011l-.878-.606c-.024-.016-.027-.046-.009-.067.02-.02.053-.023.076-.007l.844.582.765-.58c.023-.018.057-.016.077.004.02.02.017.049-.006.067l-.799.606c-.01.008-.023.012-.036.012" mask="url(#wieubk8gph)" />
                        <path fill="#27926C" d="M95.838 47.851c.026-.076.046-.155.051-.239.03-.505-.355-.939-.86-.968-.474-.028-.88.311-.954.77h-.007c-.504-.03-.938.355-.967.86-.03.504.355.937.86.967.228.013.44-.06.608-.19.142.281.423.486.761.505.505.03.938-.355.968-.859.021-.359-.169-.678-.46-.846" mask="url(#wieubk8gph)" />
                        <path fill="#27926C" d="M97.953 45.66c.026-.075.046-.154.051-.238.03-.505-.355-.94-.86-.968-.474-.028-.88.31-.953.77h-.008c-.504-.031-.937.354-.967.859-.03.505.355.938.86.968.228.013.44-.061.608-.191.142.282.423.486.761.506.505.029.938-.355.968-.86.021-.358-.168-.677-.46-.845" mask="url(#wieubk8gph)" />
                        <path fill="#27926C" d="M98.28 43.748c.027-.076.047-.156.052-.24.03-.505-.355-.938-.86-.968-.474-.028-.881.312-.954.77h-.007c-.504-.03-.938.355-.967.86-.031.504.355.937.859.968.229.013.44-.062.609-.191.142.282.423.486.76.505.506.03.939-.354.969-.86.02-.358-.17-.677-.46-.844" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M91.623 35.204c0 .405.377.733.843.733.465 0 .843-.328.843-.733 0-.405-.378-.734-.843-.734-.466 0-.843.329-.843.734M88.404 35.095c0 .576.537 1.044 1.198 1.044.662 0 1.198-.468 1.198-1.044 0-.576-.536-1.044-1.198-1.044-.66 0-1.198.468-1.198 1.044" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M89.341 32.959c0 .867.807 1.57 1.802 1.57s1.802-.703 1.802-1.57c0-.866-.807-1.57-1.802-1.57s-1.802.704-1.802 1.57" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M91.079 38.545L91.207 38.545 91.207 33.598 91.079 33.598z" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M91.152 36.272c-.017 0-.034-.005-.048-.015l-1.244-.859c-.034-.022-.04-.064-.013-.094.027-.029.075-.034.108-.01l1.195.824 1.085-.823c.03-.024.08-.02.108.007.028.028.024.071-.007.094l-1.134.86c-.014.01-.032.016-.05.016" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M96.877 37.1c0 .405.377.734.843.734.465 0 .843-.329.843-.734 0-.405-.378-.734-.843-.734-.466 0-.843.33-.843.734M93.658 36.992c0 .577.537 1.044 1.198 1.044.662 0 1.198-.467 1.198-1.044 0-.576-.536-1.043-1.198-1.043-.66 0-1.198.467-1.198 1.043" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M94.595 34.856c0 .867.807 1.57 1.802 1.57s1.802-.703 1.802-1.57c0-.867-.807-1.57-1.802-1.57s-1.802.703-1.802 1.57" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M96.333 40.441L96.461 40.441 96.461 35.495 96.333 35.495z" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M96.406 38.169c-.017 0-.034-.005-.048-.015l-1.244-.858c-.033-.023-.04-.065-.013-.095.027-.028.075-.033.108-.011l1.195.825 1.085-.823c.032-.024.08-.021.108.007.028.029.024.07-.007.095l-1.134.858c-.014.01-.032.017-.05.017" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M93.402 38.057c0 .405.377.734.843.734.465 0 .843-.329.843-.734 0-.405-.378-.734-.843-.734-.466 0-.843.33-.843.734M90.183 37.949c0 .576.537 1.044 1.198 1.044.662 0 1.198-.468 1.198-1.044 0-.576-.535-1.044-1.197-1.044s-1.199.468-1.199 1.044" mask="url(#wieubk8gph)" />
                        <path fill="#00A357" d="M91.12 35.813c0 .866.807 1.57 1.802 1.57.996 0 1.803-.704 1.803-1.57 0-.867-.807-1.57-1.802-1.57-.996 0-1.802.703-1.802 1.57" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M92.859 41.398L92.987 41.398 92.987 36.451 92.859 36.451z" mask="url(#wieubk8gph)" />
                        <path fill="#366D93" d="M92.931 39.126c-.017 0-.034-.005-.048-.015l-1.244-.858c-.034-.023-.039-.065-.013-.095.027-.028.075-.034.108-.011l1.195.825 1.085-.823c.031-.024.08-.021.108.007.028.028.024.07-.007.095l-1.134.858c-.014.01-.032.017-.05.017" mask="url(#wieubk8gph)" />
                     </g>
                  </g>
               </g>
            </svg>
         ),
      },
      {
         id: 6,
         thumbnail: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/recommend/thumbnail/06.jpg`,
         title: '동산정',
         label: '도 문화재자료 제441호',
         address: '함안군 가야읍 검암리 380-2',
         explain: '세조 때 병조판서를 지낸 정무공 이호성이 함안에 정착하면서 지은 정자로 1523년 손자 이희조가 중수했다. 현존 정자 중 가장 오랜 역사를 자랑한다. 함안천의 수려함과 드넓은 들판의 경치가 장관이다.',
         tag: [{name: '3구간', style: {color: '#fff', backgroundColor: '#9b74b1'}}],
         slideImage: slideImages(`06`, 3),
         bg: (
            <svg width="41" height="65" viewBox="0 0 41 65">
               <defs>
                  <path id="ugbbco9n8a" d="M0.111 0.332L26.039 0.332 26.039 53.995 0.111 53.995z" />
                  <path id="9imkw6qy5c" d="M0 65L41 65 41 0 0 0z" />
               </defs>
               <g fill="none" fillRule="evenodd">
                  <g>
                     <g transform="translate(-298 -572) translate(298 572)">
                        <g transform="translate(15)">
                           <mask id="t6oyifkmnb" fill="#fff">
                              <use xlinkHref="#ugbbco9n8a" />
                           </mask>
                           <path fill="#EF886B" d="M6.063 22.864v-.528c0-2.793-.672-5.534-1.88-8.053-.745-1.554-1.09-3.337-.9-5.213C3.774 4.355 7.732.614 12.506.344c5.694-.316 10.41 4.172 10.41 9.752 0 1.524-.35 2.964-.98 4.25-1.148 2.357-1.85 4.896-1.85 7.508v1.01c0 3.81 1.136 7.566 3.42 10.628 1.829 2.457 2.8 5.586 2.47 8.944-.595 5.975-5.41 10.815-11.424 11.478C6.73 54.769.112 48.717.112 41.13c0-2.767.883-5.327 2.376-7.42 2.273-3.182 3.575-6.944 3.575-10.846" mask="url(#t6oyifkmnb)" />
                        </g>
                        <mask id="b7ns70ruud" fill="#fff">
                           <use xlinkHref="#9imkw6qy5c" />
                        </mask>
                        <path fill="#366D93" d="M28.075 65L28.925 65 28.925 36 28.075 36z" mask="url(#b7ns70ruud)" />
                        <path fill="#E99F91" d="M5.051 26.319v-.452c0-2.394-.572-4.743-1.594-6.907-.638-1.328-.932-2.856-.765-4.466.416-4.045 3.776-7.25 7.825-7.48 4.828-.268 8.831 3.578 8.831 8.357 0 1.308-.298 2.543-.83 3.646-.976 2.02-1.568 4.2-1.568 6.435v.867c0 3.266.961 6.486 2.9 9.106 1.553 2.113 2.378 4.79 2.095 7.666-.506 5.128-4.59 9.276-9.692 9.84C5.618 53.664 0 48.475 0 41.978c0-2.374.744-4.568 2.014-6.368 1.929-2.728 3.037-5.953 3.037-9.291" mask="url(#b7ns70ruud)" />
                        <path fill="#366D93" d="M11.133 63L11.867 63 11.867 38 11.133 38z" mask="url(#b7ns70ruud)" />
                     </g>
                  </g>
               </g>
            </svg>
         ),
      },
   ]

   const data = {
      items
   }
   return data
}