import React from 'react';
import styled, { css } from 'styled-components';
import SVG from '../main/SVG';
import GUIDEEXPAIN from './image/invalid-name.png'

const Introduce = () => {
   return (
      <INTRODUCE>
         <div>
            <TITLE>
               <SVG types={'introduce title'} />
            </TITLE>
            <SECTION explain>
               <p>
                  역사적 의미가 깊은 고대 유적지부터 현대의 시가지까지 걸어볼 수 있으며 탁월한 경관을 조망할 수 있는 걷기 길이다.<br />
                  산성 ,고분을 포함한  코스도 대체로 완만해 쉽게 걸을 수 있으며 정자와 연못, 시장과 공원 등 역사도시 함안의 다채롭고 활기찬 풍경을 두루 감상할 수 있다.<br />
                  구간 중간에 식사나 관람을 하면서 휴식을 취할 수 있는 포인트가 있어 가족, 연인 등과 함께 알찬 하루를 즐길 수 있다.
               </p>
               <div>
                  <span>총 7구간</span>
                  <span>16.7km</span>
                  <span>소요시간 6시간 30분</span>
               </div>
            </SECTION>
            <SECTION guideExplain>
               <img src={GUIDEEXPAIN} alt={'51'} />
            </SECTION>
            <SECTION planning>
               <div className={'android'}>
                  <h4>
                     <span>Android 안드로이드</span>
                  </h4>
                  <p>
                     우측상단의 <span>Install for Android</span> 버튼을 터치하시면<br />
                     폰 바탕에 추가되어<br />
                     앱처럼 편하게 사용이 가능합니다.
                  </p>
               </div>
               <div className={'ios'}>
                  <h4>
                     <span>ios 아이폰</span>
                  </h4>
                  <p>
                     브라우저 하단의 '공유버튼
                     <svg width="11" height="14" viewBox="0 0 11 14">
                        <defs>
                           <path id="5ome8arh7a" d="M0.001 0.024L10.771 0.024 10.771 9 0.001 9z" />
                           <path id="82wmhhczfc" d="M0.485 0.417L3.599 0.417 3.599 3.53 0.485 3.53z" />
                        </defs>
                        <g fill="none" fillRule="evenodd">
                           <g transform="translate(-257 -843) translate(-10 680) translate(103 134) translate(164 29) translate(0 5)">
                              <mask id="d51kzyixdb" fill="#fff">
                                 <use xlinkHref="#5ome8arh7a" />
                              </mask>
                              <path fill="#686868" d="M2.992.024H1.195C.533.024 0 .556 0 1.216v6.591C0 8.466.533 9 1.195 9h8.383c.66 0 1.194-.533 1.194-1.193V1.216c0-.658-.535-1.192-1.194-1.192H7.78v.898h2.094v7.18H.898V.923h2.094V.024z" mask="url(#d51kzyixdb)" />
                           </g>
                           <path fill="#686868" d="M4.999 1c.332 0 .6.207.6.461v7.076c0 .255-.268.463-.6.463-.33 0-.598-.208-.598-.463V1.461c0-.254.268-.461.598-.461" transform="translate(-257 -843) translate(-10 680) translate(103 134) translate(164 29)" />
                           <path fill="#686868" d="M5.86.14c.187.187.187.492 0 .68L3.82 2.858c-.188.19-.492.19-.678 0-.19-.188-.19-.491 0-.678L5.18.14c.186-.187.491-.187.68 0" transform="translate(-257 -843) translate(-10 680) translate(103 134) translate(164 29)" />
                           <g transform="translate(-257 -843) translate(-10 680) translate(103 134) translate(164 29) translate(4)">
                              <mask id="jfnghisoud" fill="#fff">
                                 <use xlinkHref="#82wmhhczfc" />
                              </mask>
                              <path fill="#686868" d="M3.453 3.384c-.195.195-.512.195-.705 0L.633 1.267c-.197-.195-.197-.51 0-.703.193-.197.508-.197.703 0l2.117 2.115c.195.193.195.51 0 .705" mask="url(#jfnghisoud)" />
                           </g>
                        </g>
                     </svg>'을 터치한 후<br />
                     '<svg width="12" height="12" viewBox="0 0 12 12">
                        <defs>
                           <path id="5j8n46vdua" d="M0 0.453L11.547 0.453 11.547 12 0 12z" />
                        </defs>
                        <g fill="none" fillRule="evenodd">
                           <path fill="#686868" d="M5.974 4L5.026 4 5.026 6.026 3 6.026 3 6.974 5.026 6.974 5.026 9 5.974 9 5.974 6.974 8 6.974 8 6.026 5.974 6.026z" transform="translate(-159 -864) translate(-10 680) translate(103 134) translate(66 50)" />
                           <g transform="translate(-159 -864) translate(-10 680) translate(103 134) translate(66 50)">
                              <mask id="8hy8gddwmb" fill="#fff">
                                 <use xlinkHref="#5j8n46vdua" />
                              </mask>
                              <path fill="#686868" d="M10.588 10.6c0 .243-.198.44-.44.44H1.4c-.245 0-.441-.197-.441-.44V1.853c0-.244.196-.442.44-.442h8.747c.243 0 .441.198.441.442V10.6zM10.148.452H1.4C.627.452 0 1.08 0 1.853V10.6c0 .774.627 1.4 1.4 1.4h8.747c.774 0 1.4-.626 1.401-1.4V1.853c-.001-.774-.627-1.4-1.4-1.4z" mask="url(#8hy8gddwmb)" />
                           </g>
                        </g>
                     </svg>홈 화면에 추가하기'를 하시면<br />
                     홈 화면에 추가되어<br />
                     앱처럼 편하게 사용이 가능합니다.
                  </p>
               </div>
               <div className={'footer'}>
                  <div>
                     <label>제작·기획</label>
                     <p>함안군청 가야사담당관</p>
                  </div>
                  <div>
                     <label>문의</label>
                     <p>055.580.2581</p>
                  </div>
               </div>
               <div className={'phone'}>
                  <svg width="75" height="159" viewBox="0 0 75 159">
                     <defs>
                        <path id="bb1rmg83aa" d="M0.44 0.813L22.128 0.813 22.128 24.367 0.44 24.367z" />
                        <path id="j6jm1tz66c" d="M0 159.187L84.171 159.187 84.171 1 0 1z" />
                     </defs>
                     <g fill="none" fillRule="evenodd">
                        <g transform="translate(0 -816) translate(-10 680) translate(0 135)">
                           <g transform="translate(56 .187)">
                              <mask id="ijv039ezib" fill="#fff">
                                 <use xlinkHref="#bb1rmg83aa" />
                              </mask>
                              <path fill="#C09CC8" d="M3.617 21.013l13.384 3.234c3.023.73 5.745-2.002 5.003-5.022L18.258 3.974c-.82-3.34-5.115-4.288-7.266-1.606L1.354 14.385c-1.9 2.37-.69 5.914 2.263 6.628" mask="url(#ijv039ezib)" />
                           </g>
                           <path fill="#EF8A6C" d="M80.427 61.199c-1.873 1.363-7.276 5.417-7.276 5.417s-3.791-5.207-5.37-7.372c-1.465-2.015-5.253-7.217-5.253-7.217s5.252-3.942 7.276-5.417c4.05-2.95 9.712-2.074 12.644 1.954 2.934 4.03 2.029 9.686-2.021 12.635" />
                           <path fill="#E85750" d="M57.551 87.918L45.949 90.551 41.546 97.28 55.529 106.429 59.907 99.738z" />
                           <path fill="#F1A0B7" d="M43.115 25.856c-.004-2.995-2.393-5.423-5.367-5.504.002-.06.009-.117.009-.175-.005-3.048-2.479-5.516-5.526-5.512-3.048.004-5.516 2.479-5.512 5.526 0 .06.007.118.01.176-.06-.002-.118-.01-.177-.01-3.048.005-5.516 2.48-5.512 5.528.004 3.048 2.479 5.514 5.526 5.51.06 0 .117-.006.175-.008.089 2.973 2.524 5.357 5.519 5.353 2.993-.004 5.422-2.393 5.503-5.367 2.973-.09 5.356-2.523 5.352-5.517" />
                           <path fill="#7CC4A6" d="M37.358 76.896c0 6.831-5.536 12.368-12.366 12.368-6.832 0-12.369-5.537-12.369-12.368 0-6.83 5.537-12.367 12.368-12.367 6.83 0 12.367 5.537 12.367 12.367" />
                           <path fill="#FBC879" d="M15.64 38.882l-5.491 5.89c-1.017 1.089-2.724 1.15-3.816.136L.863 39.82c-1.094-1.017-1.156-2.728-.137-3.82l5.492-5.89c1.017-1.09 2.724-1.152 3.816-.137l5.47 5.086c1.095 1.017 1.157 2.728.137 3.822" />
                           <path fill="#F4A275" d="M23.983 56.275s-3.021 8.563-.756 12.09c2.267 3.525 4.535 7.807 4.535 7.807l-1.26 5.541 24.935 7.808 8.816-20.149-18.89-12.593-7.306-3.023-10.074 2.52z" />
                           <path fill="#646B7C" d="M63.441 82.627H37.543c-3.559 0-6.444-2.886-6.444-6.445V15.778c0-3.56 2.885-6.445 6.445-6.445h25.898c3.56 0 6.443 2.886 6.443 6.445v60.404c0 3.559-2.884 6.445-6.444 6.445" />
                           <mask id="zgh0uhz3pd" fill="#fff">
                              <use xlinkHref="#j6jm1tz66c" />
                           </mask>
                           <path fill="#FBF7F5" d="M32.909 78.47L68.331 78.47 68.331 14.999 32.909 14.999z" mask="url(#zgh0uhz3pd)" />
                           <path fill="#F4A275" d="M26.25 73.37s-10.074-11.082-6.045-21.912c4.03-10.83 9.571-9.822 9.571-9.822s7.808-1.008 7.556 3.777c-.252 4.786-5.037 8.312-5.037 8.312L26.25 73.37z" mask="url(#zgh0uhz3pd)" />
                           <path fill="#EE7B3B" d="M26.502 81.714L15.924 109.136 41.11 115.181 51.437 89.522z" mask="url(#zgh0uhz3pd)" />
                           <path fill="#76C1A1" d="M9.464 83.436s2.599 4.235 3.164 7.062 2.262 3.958 5.937 4.24c3.673.282 9.61-.282 10.458-.282.848 0 6.502.847 7.915 4.804 1.413 3.958 2.827 9.328 11.872 9.328 9.046 0 20.352.284 22.614 1.697 2.261 1.413 6.501 6.218 5.088 11.872-1.413 5.653-5.653 12.437-11.59 12.72-5.936.283-10.741.283-10.741.283s-6.784.283-7.35 2.827c-.565 2.544-1.696 7.066-3.957 8.48-2.262 1.413-8.48 1.98-13.003 1.98s-7.915 2.825-7.915 4.803c0 1.98-2.262 5.088-5.371 5.372-3.109.282-6.784.565-6.784.565l-.337-75.75z" mask="url(#zgh0uhz3pd)" />
                        </g>
                     </g>
                  </svg>
               </div>
            </SECTION>
         </div>
      </INTRODUCE>
   );
};

export default React.memo(Introduce);

const INTRODUCE = styled.article`
   position: absolute;
   width: 100%; height: 100%; max-height: 100%;
   padding: 80px 0px 0px 0px;
   background-color: #fefefe;
   &>div {
      width: 100%; height: 100%;
      overflow: scroll;
   }
`

const TITLE = styled.h1`
   display: flex; justify-content: center;
`
const SECTION = styled.section`
   ${props => props.explain && css`
      width: 100%; max-width: 375px;
      padding: 10px 20px;
      margin: auto;
      &>p {
         font-size: 13px; font-weight: 400; line-height: 1.62; 
         letter-spacing: -0.5px; text-align: justify;
         color: #444444;
      }
      &>div {
         display: flex; flex-wrap: wrap;
         padding: 20px 0 0 0;
         &>span {
            font-size: 13px; color: #9a7414;
            border: 1px solid #9a7414; border-radius: 20px;
            padding: 2px 10px;
            margin: 0 10px 5px 0;
         }
      }
   `}
   ${props => props.guideExplain && css`
      display: flex; justify-content: center;
      &>img {
         width: 100%; max-width: 375px;
         background-image: url(${GUIDEEXPAIN});
         background-position: center;
         background-repeat: no-repeat;
         background-size: 100% 100%;
      }
   `}
   ${props => props.planning && css`
      position: relative;
      width: 100%; max-width: 375px;
      padding: 26px 22px 16px 22px;
      margin: auto;
      background-color: #f1e7d8;
      &>.android {
         position: relative; z-index: 1;
         padding: 0px 10px; margin: 0 0 17px 0;
         &>h4 {
            &>span {
               font-weight: 500; font-size: 13px;
               padding: 0px 7px;
               color: #ffffff; background-color: #93671e;
            }
         }
         &>p {
            font-weight: 300; font-size: 14px; line-height: normal;
            padding: 7px 0px 7px 13px; letter-spacing: -0.07px;
            color: #686868; background-color: #ffffff;
            &>span {
               transform: translateY(-2px);
               display: inline-block; 
               font-weight: 800; font-size: 10px;
               padding: 3px 5px; border-radius: 8px;
               color: #ffffff; background-color: #686868;
            }
         }
      }
      &>.ios {
         position: relative; z-index: 1;
         width: 260px;
         margin: 0 0 19px auto;
         &>h4 {
            text-align: right;
            &>span {
               font-weight: 500; font-size: 13px;
               padding: 0px 7px;
               color: #ffffff; background-color: #93671e;
            }
         }
         &>p {
            font-weight: 300; font-size: 14px; line-height: normal;
            letter-spacing: -0.07px; text-align: right;
            padding: 6px 10px; 
            color: #686868; background-color: #ffffff;
            &>span {
               
            }
         }
      }
      &>.footer {
         &>div {
            display: flex; justify-content: flex-end;
            letter-spacing: -0.07px;
            color: #3f3f3f;
            margin: 0 0 5px 0;
            &>label {
               font-weight: 300; font-size: 13px; 
               padding: 0 5px;
            }
            &>p {
               font-weight: 800; font-size: 13px;
            }
         }
      }
      &>.phone {
         position: absolute; bottom: 25px; left: 0;
      }
   `}
`