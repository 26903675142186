import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import AddHome from '../AddHome';
import SVG from '../main/SVG';

const Header = () => {
   const location = useLocation()
   const [ homeLink, setHomeLink ] = useState(false)
   const [ styles, setStyles ] = useState(null)

   useEffect(() => {
      const index = ['/', '/index', '/index.html']
      if (index.includes(location.pathname)) {
         setHomeLink(false)
         setStyles(css`
            background-color: #fafbf3;
         `)
      } else {
         setHomeLink(true)
         setStyles(css`
            background-color: #ffffff;
         `)
      }
   }, [location])

   return (
      <HEADER link={homeLink} styles={styles}>
      {!homeLink && <AddHome />}
      {homeLink && <Home location={location} />}
      {location.pathname.match(/\/recommend\/[0-9]{0,}/g) && <h1>추천명소</h1>}
         <Nav />
      </HEADER>
   );
};

export default React.memo(Header);
const HEADER = styled.header`
   display: flex; justify-content: space-between; align-items: flex-end;
   height: 60px;
   padding: 5px 28px;
   z-index: 999;
   ${props => props.styles}
   ${props => props.link ? css`
      justify-content: space-between;
   ` : css`
      justify-content: flex-end;
   `}
   &>h1 {
      color: #424242; font-size: 21px; font-weight: 400;
      padding: 0px 0px 5px 0px;
   }
`

const Nav = React.memo(() => {
   const [ expand, setExpand ] = useState(false)
   return (
      <>
      {!expand ? 
      <button
         onClick={event => setExpand(true)}
      >
         <svg width="20" height="20" viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
               <path d="M0 0H32V32H0z" transform="translate(-325 -34) translate(319 28)" />
               <path fill="#D8D8D8" d="M6 6H26V10H6zM6 14H26V18H6zM6 22H26V26H6z" transform="translate(-325 -34) translate(319 28)" />
            </g>
         </svg>
      </button> :
      <NAV>
         <div>
            <button
               onClick={event => setExpand(false)}
            >
               <svg width="26" height="26" viewBox="0 0 26 26">
                  <defs>
                     <linearGradient id="kycr8j1l6a" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0%" stopColor="#FFF" stopOpacity=".5" />
                        <stop offset="100%" stopOpacity=".5" />
                     </linearGradient>
                  </defs>
                  <g fill="none" fillRule="evenodd">
                     <g stroke="#979797">
                        <g>
                           <path d="M0 0H24V24H0z" transform="translate(-146 -23) translate(147 24)" />
                           <path fill="url(#kycr8j1l6a)" d="M17 6.5L7 17.5" transform="translate(-146 -23) translate(147 24) rotate(2.726 12 12)" />
                           <path d="M7 6.5L17 17.5" transform="translate(-146 -23) translate(147 24) rotate(-2.726 12 12)" />
                        </g>
                     </g>
                  </g>
               </svg>
            </button>
            <ul>
               <li>
                  <Link to={'/introduce'}
                     onClick={event => setExpand(false)}
                  >
                     아라가야 역사순례길
                  </Link>
               </li>
               <li>
                  <Link to={'/guide'}
                     onClick={event => setExpand(false)}
                  >
                     구간안내
                  </Link>
               </li>
               <li>
                  <Link to={'/recommend'}
                     onClick={event => setExpand(false)}
                  >
                     추천명소
                  </Link>
               </li>
            </ul>
            <SVG types={'nav'} />
         </div>
      </NAV>
      }
      </>
   )
})
const NAV = styled.nav`
   position: fixed; right: 0; top: 0; z-index: 999;
   display: flex; justify-content: flex-end;
   width: 100%; height: 100%;
   background-color: rgba(0,0,0,0.4);
   &>div {
      position: relative;
      width: 252px; height: 100%;
      padding: 24px;
      background-color: #ffffff;
      &>button {
         margin: 0px 0px 24px 0px;
      }
      &>ul {
         width: 100%;
         border-top: 1px solid #979797;
         border-bottom: 1px solid #979797;
         li + li {
            border-top: 1px solid #979797;
            height: 43px;
         }
         &>li {
            &>a {
               display: flex; align-items: center;
               width: 100%; height: 41px;
               font-size: 18px; font-weight: 500;
               letter-spacing: -0.09px; line-height: 1;
               padding: 0px 0px 0px 5px;
            }
         }
      }
   }
`

const Home = React.memo((props) => {
   const { location } = props
   return (
      <Link to={location.pathname.match(/\/recommend\/[0-9]{0,}/g) ? '/recommend' : '/'}>
         <svg width="12" height="20" viewBox="0 0 12 20">
            <g fill="none" fillRule="evenodd">
               <g>
                  <g>
                     <path d="M0 0H124V44H0z" transform="translate(-16 -32) translate(0 20)" />
                     <path fill="#2A2A2A" d="M25.914 12c.328 0 .328 0 .598.146.27.147.27.147.427.416.159.27.159.27.159.598 0 .48 0 .48-.364.844l-7.91 7.723 7.91 7.722c.364.363.364.363.364.844 0 .316 0 .316-.159.592-.158.275-.158.275-.427.422-.27.146-.27.146-.598.146-.492 0-.492 0-.832-.328l-8.695-8.52c-.211-.187-.211-.187-.3-.404-.087-.217-.087-.217-.087-.474 0-.258 0-.258.088-.475.088-.217.088-.217.299-.404l8.695-8.52c.34-.328.34-.328.832-.328z" transform="translate(-16 -32) translate(0 20)" />
                  </g>
               </g>
            </g>
         </svg>
      </Link>
   )
})