import React from 'react';
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import "swiper/swiper.scss";

SwiperCore.use([Pagination])

const Slide = (props) => {
   const { title, slideImage } = props
   return (
      <Swiper
         spaceBetween={0}
         slidesPerView={1}
         pagination={{ clickable: true }}
         style={{ maxWidth: 400 }}
      >
         {slideImage.length > 0 && slideImage.map((s, sdex) => 
            <SwiperSlide key={sdex}>
               <div>
                  <img src={s} alt={title}
                     width={'100%'} height={'100%'}
                  />
               </div>
            </SwiperSlide>
         )}
      </Swiper>
   );
};

export default React.memo(Slide);