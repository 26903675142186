import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import awsExports from "./aws-exports"
import { ServiceWorker } from '@aws-amplify/core'
import { BrowserRouter } from 'react-router-dom'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

Amplify.configure(awsExports)

const serviceWorker = new ServiceWorker()

ReactDOM.render(
   <React.StrictMode>
      <BrowserRouter>
         <App />
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
  );
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
if (window.location.href.match(/(localhost)/)) {
   
} else {
   serviceWorker.register('/service-worker.js', '/')
}
