export default function useGuide() {
   const data = [
      {
         title: '전체',
         dist: 0,
         time: 0,
         rest: 0,
         tour: 0,
         start: '',
         end: '',
         titleColor: '#444444',
         infoExpand: false,
         info: [
            {
               title: '전체',
               subject: null,
               rotate: 0,
               link: null,
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/section/00.png`,
            }
         ],
      },
      {
         title: 1,
         dist: 1.4,
         time: 25,
         rest: 0,
         tour: 0,
         start: '함안시외버스터미널',
         end: '세븐일레븐',
         titleColor: '#008834',
         infoColor: '#8AC569',
         infoExpand: false,
         map: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/section/01.png`,
         info: [
            {
               section: 1,
               title: 1,
               subject: '함안시외버스터미널',
               direction: (
                  <span className={'start'}>출발</span>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/01_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2791542,128.4093065&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 1,
               title: 2,
               subject: '쌈지공원',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-128 -217) translate(128 217)">
                        <path fill="#8AC569" d="M16.84 0C8.121 0 .93 6.613 0 15.087h20.188l-2.492-2.492c-.713-.714-.713-1.891 0-2.64.714-.714 1.891-.714 2.641 0l5.637 5.673c.75.749.75 1.89 0 2.64l-5.673 5.673c-.357.357-.82.535-1.32.535-.5 0-.964-.178-1.32-.535-.714-.713-.714-1.891 0-2.64l2.458-2.458H.005c.332 2.973 1.436 5.717 3.106 8.024 3.08 4.256 8.083 7.029 13.73 7.029 9.347 0 16.947-7.6 16.947-16.948S26.188 0 16.84 0" mask="url(#fm4i4naljb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/02_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2778276,128.4079493&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 1,
               title: 3,
               subject: '가야시장',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-128 -217) translate(128 217)">
                        <path fill="#8AC569" d="M16.84 0C8.121 0 .93 6.613 0 15.087h20.188l-2.492-2.492c-.713-.714-.713-1.891 0-2.64.714-.714 1.891-.714 2.641 0l5.637 5.673c.75.749.75 1.89 0 2.64l-5.673 5.673c-.357.357-.82.535-1.32.535-.5 0-.964-.178-1.32-.535-.714-.713-.714-1.891 0-2.64l2.458-2.458H.005c.332 2.973 1.436 5.717 3.106 8.024 3.08 4.256 8.083 7.029 13.73 7.029 9.347 0 16.947-7.6 16.947-16.948S26.188 0 16.84 0" mask="url(#fm4i4naljb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/03_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2775971,128.4065670&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 1,
               title: 4,
               subject: '아라길(가야시장)',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-128 -326) translate(128 326)">
                        <path fill="#8AC569" d="M16.948 33.896c8.718 0 15.91-6.613 16.84-15.087h-20.19l2.494 2.492c.713.714.713 1.89 0 2.64-.714.714-1.892.714-2.641 0l-5.637-5.673c-.75-.75-.75-1.891 0-2.64l5.673-5.673c.357-.357.82-.536 1.32-.536.499 0 .963.179 1.32.536.714.713.714 1.89 0 2.64l-2.458 2.458h20.114C32.838 6.596 25.655 0 16.948 0 7.6 0 0 7.6 0 16.948s7.6 16.948 16.948 16.948" mask="url(#4vecqvic1b)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/04_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2767092,128.4066381&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 1,
               title: 5,
               subject: '아라길(세븐일레븐 옆)',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-128 -326) translate(128 326)">
                        <path fill="#8AC569" d="M16.948 33.896c8.718 0 15.91-6.613 16.84-15.087h-20.19l2.494 2.492c.713.714.713 1.89 0 2.64-.714.714-1.892.714-2.641 0l-5.637-5.673c-.75-.75-.75-1.891 0-2.64l5.673-5.673c.357-.357.82-.536 1.32-.536.499 0 .963.179 1.32.536.714.713.714 1.89 0 2.64l-2.458 2.458h20.114C32.838 6.596 25.655 0 16.948 0 7.6 0 0 7.6 0 16.948s7.6 16.948 16.948 16.948" mask="url(#4vecqvic1b)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/05_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2787856,128.4174658&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            }
         ]
      },
      {
         title: 2,
         dist: 2,
         time: 35,
         rest: 0,
         tour: 0,
         start: '세븐일레븐',
         end: '동산정',
         titleColor: '#f58300',
         infoColor: '#FF9A00',
         infoExpand: false,
         map: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/section/02.png`,
         info: [
            {
               section: 2,
               title: 6,
               subject: '함안천 옆',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-469 -658) translate(469 658)">
                        <path fill="#FF9A00" d="M0 16.948c0 8.718 6.612 15.91 15.088 16.84v-20.19l-2.492 2.494c-.714.713-1.893.713-2.642 0-.713-.714-.713-1.892 0-2.641l5.673-5.637c.75-.75 1.893-.75 2.641 0l5.672 5.673c.357.357.537.82.537 1.32 0 .499-.18.963-.537 1.32-.713.714-1.89.714-2.64 0l-2.457-2.458v20.114c8.457-.945 15.054-8.128 15.054-16.835C33.897 7.6 26.296 0 16.947 0 7.602 0 0 7.6 0 16.948" mask="url(#bzldpzu94b)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/06_01.jpeg`,
               link: `https://www.google.com/maps?q=%EA%B2%BD%EC%83%81%EB%82%A8%EB%8F%84+%ED%95%A8%EC%95%88%EA%B5%B0+%EA%B0%80%EC%95%BC%EC%9D%8D+%EA%B2%80%EC%95%94%EB%A6%AC+1031-6&ftid=0x356f24b4c647b9c3:0x729b572f732bdf38&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 2,
               title: 7,
               subject: '하검다리 아래',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-128 -217) translate(128 217)">
                        <path fill="#FF9A00" d="M16.84 0C8.121 0 .93 6.613 0 15.087h20.188l-2.492-2.492c-.713-.714-.713-1.891 0-2.64.714-.714 1.891-.714 2.641 0l5.637 5.673c.75.749.75 1.89 0 2.64l-5.673 5.673c-.357.357-.82.535-1.32.535-.5 0-.964-.178-1.32-.535-.714-.713-.714-1.891 0-2.64l2.458-2.458H.005c.332 2.973 1.436 5.717 3.106 8.024 3.08 4.256 8.083 7.029 13.73 7.029 9.347 0 16.947-7.6 16.947-16.948S26.188 0 16.84 0" mask="url(#fm4i4naljb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/07_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2747683,128.4183037&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 2,
               title: 8,
               subject: '중검다리 옆',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-469 -658) translate(469 658)">
                        <path fill="#FF9A00" d="M0 16.948c0 8.718 6.612 15.91 15.088 16.84v-20.19l-2.492 2.494c-.714.713-1.893.713-2.642 0-.713-.714-.713-1.892 0-2.641l5.673-5.637c.75-.75 1.893-.75 2.641 0l5.672 5.673c.357.357.537.82.537 1.32 0 .499-.18.963-.537 1.32-.713.714-1.89.714-2.64 0l-2.457-2.458v20.114c8.457-.945 15.054-8.128 15.054-16.835C33.897 7.6 26.296 0 16.947 0 7.602 0 0 7.6 0 16.948" mask="url(#bzldpzu94b)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/08_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2718094,128.4197111&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 2,
               title: 9,
               subject: '중검마을 앞',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-469 -658) translate(469 658)">
                        <path fill="#FF9A00" d="M0 16.948c0 8.718 6.612 15.91 15.088 16.84v-20.19l-2.492 2.494c-.714.713-1.893.713-2.642 0-.713-.714-.713-1.892 0-2.641l5.673-5.637c.75-.75 1.893-.75 2.641 0l5.672 5.673c.357.357.537.82.537 1.32 0 .499-.18.963-.537 1.32-.713.714-1.89.714-2.64 0l-2.457-2.458v20.114c8.457-.945 15.054-8.128 15.054-16.835C33.897 7.6 26.296 0 16.947 0 7.602 0 0 7.6 0 16.948" mask="url(#bzldpzu94b)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/09_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2722172,128.4201899&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 2,
               title: 10,
               subject: '함안천(상검마을 앞)',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-469 -658) translate(469 658)">
                        <path fill="#FF9A00" d="M0 16.948c0 8.718 6.612 15.91 15.088 16.84v-20.19l-2.492 2.494c-.714.713-1.893.713-2.642 0-.713-.714-.713-1.892 0-2.641l5.673-5.637c.75-.75 1.893-.75 2.641 0l5.672 5.673c.357.357.537.82.537 1.32 0 .499-.18.963-.537 1.32-.713.714-1.89.714-2.64 0l-2.457-2.458v20.114c8.457-.945 15.054-8.128 15.054-16.835C33.897 7.6 26.296 0 16.947 0 7.602 0 0 7.6 0 16.948" mask="url(#bzldpzu94b)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/10_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2666295,128.4226344&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            }
         ]
      },
      {
         title: 3,
         dist: 2.2,
         time: 40,
         rest: 10,
         tour: 0,
         start: '동산정',
         end: '함안역',
         titleColor: '#915ebe',
         infoColor: '#915EBE',
         infoExpand: false,
         map: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/section/03.png`,
         info: [
            {
               section: 3,
               title: 11,
               subject: '동산정 앞',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <defs>
                        <path id="mhs30t8yaa" d="M0 0L33.896 0 33.896 33.896 0 33.896z" />
                     </defs>
                     <g fill="none" fillRule="evenodd">
                        <g>
                           <g transform="translate(-128 -833) translate(128 833)">
                              <mask id="4s5usuc4eb" fill="#fff">
                                 <use xlinkHref="#mhs30t8yaa" />
                              </mask>
                              <path fill="#915EBE" d="M16.822 0C7.474.07-.068 7.725 0 17.073c.07 9.348 7.725 16.891 17.073 16.822 2.362-.018 4.606-.522 6.644-1.413v-9.839h-11.61c-.328 0-.617-.118-.876-.279-.5-.282-.85-.793-.855-1.415l-.074-10.045L8.08 13.16c-.635.645-1.69.652-2.367.017-.644-.635-.652-1.69-.017-2.367l5.048-5.092c.667-.676 1.691-.684 2.368-.017l5.124 5.05c.322.316.485.73.488 1.178.004.448-.153.865-.47 1.188-.636.644-1.692.652-2.368.017l-2.256-2.222.062 8.352h11.72c.932 0 1.69.756 1.69 1.69 0 .005-.005.01-.005.017v9.52c4.153-3.12 6.84-8.086 6.8-13.669C33.825 7.474 26.17-.068 16.821 0" mask="url(#4s5usuc4eb)" />
                           </g>
                        </g>
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/11_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2663032,128.4242330&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 3,
               title: 12,
               subject: '대사교 동편',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-128 -885) translate(128 885)">
                        <path fill="#915EBE" d="M16.948 0C7.6 0 0 7.6 0 16.948c0 4.71 1.932 8.972 5.042 12.047 3.821-1.822 7.018-4.74 7.057-4.774l.002.002c1.22-1.095 3.661-2.985 4.178-5.073.732-2.965-.46-5.587-.46-5.587l-2.498 2.498c-.714.713-1.891.713-2.641 0-.713-.714-.713-1.891 0-2.641l5.674-5.637c.749-.75 1.891-.75 2.64 0l5.673 5.673c.357.357.535.82.535 1.32 0 .5-.178.964-.535 1.32-.714.714-1.891.714-2.64 0L19.529 13.6s1.354 3.527.203 7.505c-.764 2.64-4.256 5.302-5.023 6.069-.111.11-.253.208-.406.298-1.122.94-3.292 2.654-5.851 4.119 2.501 1.457 5.398 2.306 8.496 2.306 9.348 0 16.948-7.6 16.948-16.948S26.296 0 16.948 0" mask="url(#2cz6ppjfrb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/12_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2552157,128.4262769&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 3,
               title: 13,
               subject: '대사교 서편',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-129 -937) translate(129 937)">
                        <path fill="#915EBE" d="M12.482 9.94h8.498V7.878c0-.657.71-1.067 1.279-.74l6.93 3.982c.4.23.4.81 0 1.04l-7.046 4.049c-.518.297-1.163-.076-1.163-.673V13.34h-8.22c-2.408 0-4.607 1.61-5.12 3.963-.712 3.267 1.74 6.239 4.978 6.239h1.62c.628 0 1.136.508 1.136 1.136v1.213c0 .58-.47 1.05-1.052 1.05h-1.377c-4.424 0-8.363-3.239-8.788-7.643-.489-5.077 3.421-9.36 8.325-9.36m1.64 23.83c11.63 1.913 21.56-8.016 19.647-19.648C32.61 7.077 26.929 1.396 19.885.238 8.252-1.675-1.675 8.252.236 19.884c1.16 7.044 6.84 12.727 13.884 13.885" mask="url(#kwayg635eb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/13_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2550337,128.4247302&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 3,
               title: 14,
               subject: '함안역 동편 횡단보도',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-128 -992) translate(128 992)">
                        <path fill="#915EBE" d="M16.948 33.896c8.718 0 15.91-6.613 16.84-15.087H13.6l2.493 2.492c.712.714.712 1.89 0 2.64-.714.714-1.892.714-2.64 0l-5.639-5.673c-.749-.75-.749-1.891 0-2.64l5.674-5.673c.357-.357.82-.536 1.32-.536.5 0 .963.179 1.32.536.714.713.714 1.89 0 2.64l-2.458 2.458h20.114C32.838 6.596 25.654 0 16.948 0 7.6 0 0 7.6 0 16.948s7.6 16.948 16.948 16.948" mask="url(#b0ebpx37cb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/14_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2491343,128.4274624&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
         ],
      },
      {
         title: 4,
         dist: 2.6,
         time: 55,
         rest: 10,
         tour: 0,
         start: '함안역',
         end: '성산산성(서문)',
         titleColor: '#fdce05',
         infoColor: '#FDCE05',
         infoExpand: false,
         map: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/section/04.png`,
         info: [
            {
               section: 4,
               title: 15,
               subject: '함안역 서편 횡단보도',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-470 -166) translate(470 166)">
                        <path fill="#FDCE05" d="M16.84 0C8.121 0 .93 6.613 0 15.087h20.19l-2.494-2.492c-.713-.714-.713-1.891 0-2.64.713-.714 1.891-.714 2.64 0l5.639 5.672c.748.75.748 1.892 0 2.641L20.3 23.941c-.356.357-.82.535-1.32.535-.5 0-.963-.178-1.32-.535-.715-.713-.715-1.891 0-2.64l2.457-2.458H.007C.95 27.3 8.133 33.896 16.84 33.896c9.349 0 16.948-7.6 16.948-16.948S26.188 0 16.84 0" mask="url(#vp4uuacswb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/15_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2498344,128.4220889&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 4,
               title: 16,
               subject: '무진정입구',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-470 -166) translate(470 166)">
                        <path fill="#FDCE05" d="M16.84 0C8.121 0 .93 6.613 0 15.087h20.19l-2.494-2.492c-.713-.714-.713-1.891 0-2.64.713-.714 1.891-.714 2.64 0l5.639 5.672c.748.75.748 1.892 0 2.641L20.3 23.941c-.356.357-.82.535-1.32.535-.5 0-.963-.178-1.32-.535-.715-.713-.715-1.891 0-2.64l2.457-2.458H.007C.95 27.3 8.133 33.896 16.84 33.896c9.349 0 16.948-7.6 16.948-16.948S26.188 0 16.84 0" mask="url(#vp4uuacswb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/16_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2548256,128.4233663&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 4,
               title: 17,
               subject: '괴산재 뒤편',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-470 -277) translate(470 277)">
                        <path fill="#FDCE05" d="M16.948 33.895c8.719 0 15.91-6.612 16.84-15.087H13.6l2.492 2.492c.713.715.713 1.892 0 2.64-.714.714-1.891.714-2.641 0l-5.637-5.672c-.75-.75-.75-1.892 0-2.64l5.673-5.674c.357-.356.82-.535 1.32-.535.5 0 .964.18 1.32.535.714.714.714 1.891 0 2.641l-2.458 2.458h20.114C32.838 6.596 25.655 0 16.948 0 7.6 0 0 7.601 0 16.947c0 9.349 7.6 16.948 16.948 16.948" mask="url(#ml8tnuvj4b)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/17_01.jpeg`,
               link: `https://www.google.com/maps/place/%EA%B2%BD%EC%83%81%EB%82%A8%EB%8F%84+%ED%95%A8%EC%95%88%EA%B5%B0+%EA%B0%80%EC%95%BC%EC%9D%8D+%EA%B4%91%EC%A0%95%EB%A6%AC+12-2/@35.255733,128.420476,17z/data=!3m1!4b1!4m5!3m4!1s0x356f24c01a927983:0x56ca504124e52010!8m2!3d35.255733!4d128.4226647?hl=ko-KR`,
            },
            {
               section: 4,
               title: 18,
               subject: '성산 중턱',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-470 -331) translate(470 331)">
                        <path fill="#FDCE05" d="M.298 13.796c-1.623 8.566 3.536 16.863 11.689 19.354l3.758-19.836-2.913 1.984c-.834.569-1.991.35-2.594-.491-.568-.834-.349-1.991.491-2.594l6.624-4.483c.875-.597 1.997-.385 2.593.491l4.519 6.63c.284.417.373.906.28 1.397-.093.49-.355.913-.772 1.197-.834.568-1.99.349-2.594-.491l-1.957-2.873-3.744 19.762c8.486.645 16.303-5.184 17.923-13.739 1.74-9.185-4.313-18.066-13.497-19.806C10.919-1.442 2.038 4.611.298 13.796" mask="url(#n4bn3f2skb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/18_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2564155,128.4181065&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 4,
               title: 19,
               subject: '성산산성 입구',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-470 -277) translate(470 277)">
                        <path fill="#FDCE05" d="M16.948 33.895c8.719 0 15.91-6.612 16.84-15.087H13.6l2.492 2.492c.713.715.713 1.892 0 2.64-.714.714-1.891.714-2.641 0l-5.637-5.672c-.75-.75-.75-1.892 0-2.64l5.673-5.674c.357-.356.82-.535 1.32-.535.5 0 .964.18 1.32.535.714.714.714 1.891 0 2.641l-2.458 2.458h20.114C32.838 6.596 25.655 0 16.948 0 7.6 0 0 7.601 0 16.947c0 9.349 7.6 16.948 16.948 16.948" mask="url(#ml8tnuvj4b)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/19_01.jpeg`,
               link: `https://www.google.com/maps?q=%EA%B2%BD%EC%83%81%EB%82%A8%EB%8F%84+%ED%95%A8%EC%95%88%EA%B5%B0+%EA%B0%80%EC%95%BC%EC%9D%8D+%EA%B4%91%EC%A0%95%EB%A6%AC+756&ftid=0x356f2495e4d30a85:0x9e9fa64eebc1d428&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 4,
               title: 20,
               subject: '성산산성 동문',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-470 -166) translate(470 166)">
                        <path fill="#FDCE05" d="M16.84 0C8.121 0 .93 6.613 0 15.087h20.19l-2.494-2.492c-.713-.714-.713-1.891 0-2.64.713-.714 1.891-.714 2.64 0l5.639 5.672c.748.75.748 1.892 0 2.641L20.3 23.941c-.356.357-.82.535-1.32.535-.5 0-.963-.178-1.32-.535-.715-.713-.715-1.891 0-2.64l2.457-2.458H.007C.95 27.3 8.133 33.896 16.84 33.896c9.349 0 16.948-7.6 16.948-16.948S26.188 0 16.84 0" mask="url(#vp4uuacswb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/20_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2570501,128.4168070&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 4,
               title: 21,
               subject: '성산산성 북성벽',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-469 -499) translate(469 499)">
                        <path fill="#FDCE05" d="M27.324 29.604c-1.102-.014-1.985-.917-1.975-2.019 0-.06-.021-6.615-4.228-10.822-2.85-2.849-6.996-4.145-9.332-4.679l1.31 2.318c.545.962.207 2.182-.754 2.726-.311.176-.65.26-.983.26-.698 0-1.375-.366-1.743-1.016l-3.427-6.059c-.506-.895-.251-2.028.59-2.62l5.78-4.067c.907-.635 2.154-.418 2.788.485.636.904.418 2.151-.485 2.786L12.944 8.25c2.846.68 7.573 2.254 11.005 5.686 3.83 3.83 4.945 8.92 5.269 11.692l2.416.031c1.503-2.537 2.382-5.488 2.382-8.65C34.016 7.615 26.4 0 17.009 0 7.615 0 0 7.615 0 17.008c0 9.393 7.614 17.008 17.008 17.008 4.383 0 8.368-1.674 11.383-4.398l-1.068-.014z" mask="url(#ureknlrzfb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/21_01.jpeg`,
               link: `https://www.google.com/maps?q=%EA%B2%BD%EC%83%81%EB%82%A8%EB%8F%84+%ED%95%A8%EC%95%88%EA%B5%B0+%EA%B0%80%EC%95%BC%EC%9D%8D+%EA%B4%91%EC%A0%95%EB%A6%AC+781-2&ftid=0x356f24962e79d033:0x29c0b703bfb5f57f&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
         ],
      },
      {
         title: 5,
         dist: 2.2,
         time: 40,
         rest: 0,
         tour: 40,
         start: '성산산성(서문)',
         end: '함안박물관',
         titleColor: '#cd3047',
         infoColor: '#CD3047',
         infoExpand: false,
         map: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/section/05.png`,
         info: [
            {
               section: 5,
               title: 22,
               subject: '성산산성 서문',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-469 -658) translate(469 658)">
                        <path fill="#CD3047" d="M0 16.948c0 8.718 6.612 15.91 15.088 16.84v-20.19l-2.492 2.494c-.714.713-1.893.713-2.642 0-.713-.714-.713-1.892 0-2.641l5.673-5.637c.75-.75 1.893-.75 2.641 0l5.672 5.673c.357.357.537.82.537 1.32 0 .499-.18.963-.537 1.32-.713.714-1.89.714-2.64 0l-2.457-2.458v20.114c8.457-.945 15.054-8.128 15.054-16.835C33.897 7.6 26.296 0 16.947 0 7.602 0 0 7.6 0 16.948" mask="url(#bzldpzu94b)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/22_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2553192,128.4134663&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 5,
               title: 23,
               subject: '백산마을',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-469 -712) translate(469 712)">
                        <path fill="#CD3047" d="M16.944 0C8.224-.05.996 6.52.016 14.99l20.189.116-2.478-2.506c-.71-.718-.702-1.895.016-2.64.718-.71 1.894-.703 2.639.015l5.605 5.705c.745.754.738 1.896-.015 2.641l-5.706 5.64c-.358.354-.823.53-1.323.527-.5-.003-.962-.184-1.317-.543-.71-.717-.703-1.895.015-2.64l2.473-2.444L0 18.745c.896 8.463 8.04 15.1 16.748 15.15 9.347.054 16.99-7.5 17.046-16.849C33.847 7.698 26.292.054 16.944 0" mask="url(#0hy9vowphb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/23_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2573414,128.4109688&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 5,
               title: 24,
               subject: '백산마을회관',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-469 -766) translate(469 766)">
                        <path fill="#CD3047" d="M16.93 0C7.592 0 0 7.592 0 16.93c0 9.338 7.591 16.93 16.93 16.93 1.604 0 3.148-.238 4.619-.656v-15.92h-9.433l2.495 2.494c.712.75.712 1.925 0 2.638-.357.356-.82.534-1.32.534-.498 0-.963-.178-1.319-.534L6.306 16.75c-.748-.749-.748-1.889 0-2.638l5.631-5.667c.748-.713 1.926-.713 2.639 0 .71.748.71 1.925 0 2.638l-2.497 2.495h11.192c.54 0 1.012.231 1.35.592.302.3.49.715.49 1.174v.012c0 .026.014.048.014.075 0 .027-.014.048-.015.074v16.24c5.213-2.889 8.75-8.442 8.75-14.814C33.86 7.592 26.27 0 16.93 0" mask="url(#vo0mx1xvhb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/24_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2575719,128.4099834&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 5,
               title: 25,
               subject: '백산마을회관 횡단보도',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-469 -821) translate(469 821)">
                        <path fill="#CD3047" d="M29.45 5.506c-6.308-6.9-17.045-7.38-23.943-1.072-6.9 6.307-7.381 17.044-1.074 23.944 4.07 4.45 9.982 6.213 15.519 5.22-.032-.131-.081-.258-.081-.4V22.38l-8.237-8.911-.16 3.529c-.044 1.008-.913 1.802-1.947 1.78-1.01-.043-1.803-.913-1.782-1.947l.384-7.99c.047-1.057.889-1.828 1.948-1.78l8.015.359c.504.022.949.244 1.286.613.337.368.518.83.495 1.335-.045 1.009-.914 1.803-1.948 1.782l-3.53-.158 8.654 9.359c.38.418.518.952.457 1.467v10.729c1.743-.732 3.398-1.75 4.872-3.098 6.899-6.308 7.38-17.045 1.072-23.944" mask="url(#ebgsycb4rb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/25_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2584231,128.4094098&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 5,
               title: 26,
               subject: '도동마을',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-469 -878) translate(469 878)">
                        <path fill="#CD3047" d="M16.948 33.896c8.718 0 15.91-6.613 16.84-15.087H13.6l2.493 2.492c.712.714.712 1.89 0 2.64-.714.714-1.892.714-2.64 0l-5.639-5.673c-.749-.75-.749-1.891 0-2.64l5.674-5.673c.357-.357.82-.535 1.32-.535.5 0 .963.178 1.32.535.714.713.714 1.89 0 2.64l-2.458 2.458h20.114C32.838 6.596 25.654 0 16.948 0 7.6 0 0 7.6 0 16.948s7.6 16.948 16.948 16.948" mask="url(#5je0iyebtb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/26_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2611106,128.4066927&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 5,
               title: 27,
               subject: '도동마을 삼거리',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-469 -934) translate(469 934)">
                        <path fill="#CD3047" d="M0 16.93c0 9.338 7.592 16.93 16.93 16.93 6.372 0 11.925-3.538 14.814-8.75h-16.24c-.025.001-.048.014-.074.014-.027 0-.048-.013-.075-.014h-.012c-.459 0-.874-.187-1.174-.489-.36-.337-.593-.81-.593-1.35V12.08l-2.495 2.496c-.713.712-1.889.712-2.637 0-.713-.713-.713-1.89 0-2.638l5.667-5.632c.748-.748 1.89-.748 2.637 0l5.668 5.667c.356.357.534.82.534 1.32 0 .498-.178.962-.534 1.318-.713.713-1.89.713-2.638 0l-2.495-2.495v9.433h15.92c.418-1.47.656-3.015.656-4.618C33.86 7.592 26.27 0 16.93 0 7.592 0 0 7.592 0 16.93" mask="url(#g40qzhg1vb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/27_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2623959,128.4059377&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
         ],
      },
      {
         title: 6,
         dist: 3.7,
         time: 70,
         rest: 0,
         tour: 0,
         start: '함안박물관',
         end: '남문마을 배수장',
         titleColor: '#3c51b9',
         infoColor: '#3c51b9',
         infoExpand: false,
         map: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/section/06.png`,
         info: [
            {
               section: 6,
               title: 28,
               subject: '함안박물관',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-830 -177) translate(830 177)">
                        <path fill="#3C51B9" d="M0 16.948c0 8.718 6.612 15.91 15.087 16.84V13.599l-2.492 2.493c-.713.713-1.891.713-2.64 0-.714-.714-.714-1.892 0-2.641l5.673-5.637c.749-.749 1.89-.749 2.64 0l5.673 5.673c.357.357.536.82.536 1.32 0 .499-.18.963-.536 1.32-.713.714-1.891.714-2.64 0l-2.458-2.458v20.114c8.458-.946 15.053-8.128 15.053-16.835C33.896 7.6 26.296 0 16.948 0S0 7.6 0 16.948" mask="url(#uk5bh2ksgb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/28_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2696992,128.4038120&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 6,
               title: 29,
               subject: '말이산 4호분',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-830 -232) translate(830 232)">
                        <path fill="#3C51B9" d="M16.84 0C8.123 0 .93 6.613 0 15.087h20.189l-2.493-2.492c-.712-.714-.712-1.891 0-2.64.714-.714 1.892-.714 2.64 0l5.639 5.672c.749.75.749 1.892 0 2.641l-5.673 5.673c-.357.357-.821.535-1.32.535-.5 0-.964-.178-1.321-.535-.714-.713-.714-1.891 0-2.64l2.458-2.459H.005C.95 27.3 8.134 33.896 16.84 33.896c9.348 0 16.947-7.6 16.947-16.948S26.188 0 16.84 0" mask="url(#mb3rntpcmb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/29_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2710566,128.4060463&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 6,
               title: 30,
               subject: '말이산 2호분',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-830 -286) translate(830 286)">
                        <path fill="#3C51B9" d="M16.948 33.896c8.719 0 15.91-6.613 16.84-15.087h-20.19l2.494 2.492c.713.714.713 1.89 0 2.64-.714.714-1.891.714-2.641 0l-5.637-5.672c-.75-.75-.75-1.892 0-2.641l5.673-5.673c.357-.357.82-.535 1.32-.535.499 0 .963.178 1.32.535.714.713.714 1.89 0 2.64l-2.458 2.459h20.114C32.838 6.596 25.655 0 16.948 0 7.6 0 0 7.6 0 16.948s7.6 16.948 16.948 16.948" mask="url(#rexpkr50ab)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/30_01.jpeg`,
               link: `https://www.google.com/maps/place/35%C2%B016'20.4%22N+128%C2%B024'20.0%22E/@35.2723385,128.4033651,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d35.2723385!4d128.4055538?hl=ko-KR`,
            },
            {
               section: 6,
               title: 31,
               subject: '말이산 45호분',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-830 -177) translate(830 177)">
                        <path fill="#3C51B9" d="M0 16.948c0 8.718 6.612 15.91 15.087 16.84V13.599l-2.492 2.493c-.713.713-1.891.713-2.64 0-.714-.714-.714-1.892 0-2.641l5.673-5.637c.749-.749 1.89-.749 2.64 0l5.673 5.673c.357.357.536.82.536 1.32 0 .499-.18.963-.536 1.32-.713.714-1.891.714-2.64 0l-2.458-2.458v20.114c8.458-.946 15.053-8.128 15.053-16.835C33.896 7.6 26.296 0 16.948 0S0 7.6 0 16.948" mask="url(#uk5bh2ksgb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/31_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2728175,128.4053124&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 6,
               title: 32,
               subject: '말이산 1호분',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-830 -177) translate(830 177)">
                        <path fill="#3C51B9" d="M0 16.948c0 8.718 6.612 15.91 15.087 16.84V13.599l-2.492 2.493c-.713.713-1.891.713-2.64 0-.714-.714-.714-1.892 0-2.641l5.673-5.637c.749-.749 1.89-.749 2.64 0l5.673 5.673c.357.357.536.82.536 1.32 0 .499-.18.963-.536 1.32-.713.714-1.891.714-2.64 0l-2.458-2.458v20.114c8.458-.946 15.053-8.128 15.053-16.835C33.896 7.6 26.296 0 16.948 0S0 7.6 0 16.948" mask="url(#uk5bh2ksgb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/32_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2749027,128.4036608&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 6,
               title: 33,
               subject: '말이산고분군 진입로',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-830 -456) translate(830 456)">
                        <path fill="#3C51B9" d="M16.948 0C7.6 0 0 7.6 0 16.947c0 5.776 2.906 10.876 7.326 13.938-1.38-6.358-.67-9.408-.228-10.524.654-2.25 1.755-3.857 2.652-4.865.964-1.207 3.37-3.183 5.767-3.778 4.036-1.003 7.577.287 7.577.287l-2.421-2.422c-.692-.727-.692-1.868 0-2.56.346-.345.796-.519 1.28-.519s.934.174 1.28.52l5.728 5.5c.727.726.727 1.832 0 2.56l-5.465 5.5c-.727.691-1.868.691-2.56 0-.692-.727-.692-1.868 0-2.56l2.42-2.42s-2.54-1.158-5.415-.448c-1.506.372-3.025 1.406-4.178 2.29-.632.517-2.34 2.577-2.761 5.664-.182 1.716-.15 4.944 1.247 10.11 1.494.431 3.067.676 4.699.676 9.348 0 16.948-7.6 16.948-16.949C33.896 7.6 26.296 0 16.948 0" mask="url(#dh1h4069ab)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/33_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2758522,128.4039022&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 6,
               title: 34,
               subject: '아라길(해동APT앞)',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-830 -286) translate(830 286)">
                        <path fill="#3C51B9" d="M16.948 33.896c8.719 0 15.91-6.613 16.84-15.087h-20.19l2.494 2.492c.713.714.713 1.89 0 2.64-.714.714-1.891.714-2.641 0l-5.637-5.672c-.75-.75-.75-1.892 0-2.641l5.673-5.673c.357-.357.82-.535 1.32-.535.499 0 .963.178 1.32.535.714.713.714 1.89 0 2.64l-2.458 2.459h20.114C32.838 6.596 25.655 0 16.948 0 7.6 0 0 7.6 0 16.948s7.6 16.948 16.948 16.948" mask="url(#rexpkr50ab)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/34_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2761314,128.4039622&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 6,
               title: 35,
               subject: '관동제방',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-830 -286) translate(830 286)">
                        <path fill="#3C51B9" d="M16.948 33.896c8.719 0 15.91-6.613 16.84-15.087h-20.19l2.494 2.492c.713.714.713 1.89 0 2.64-.714.714-1.891.714-2.641 0l-5.637-5.672c-.75-.75-.75-1.892 0-2.641l5.673-5.673c.357-.357.82-.535 1.32-.535.499 0 .963.178 1.32.535.714.713.714 1.89 0 2.64l-2.458 2.459h20.114C32.838 6.596 25.655 0 16.948 0 7.6 0 0 7.6 0 16.948s7.6 16.948 16.948 16.948" mask="url(#rexpkr50ab)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/35_01.jpeg`,
               link: `https://www.google.com/maps?q=%EA%B2%BD%EC%83%81%EB%82%A8%EB%8F%84+%ED%95%A8%EC%95%88%EA%B5%B0+%EA%B0%80%EC%95%BC%EC%9D%8D+%EB%8F%84%ED%95%AD%EB%A6%AC+1353-1&ftid=0x356f2359ad501ef5:0x4272614cc2064c5a&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 6,
               title: 36,
               subject: '관동교',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-830 -232) translate(830 232)">
                        <path fill="#3C51B9" d="M16.84 0C8.123 0 .93 6.613 0 15.087h20.189l-2.493-2.492c-.712-.714-.712-1.891 0-2.64.714-.714 1.892-.714 2.64 0l5.639 5.672c.749.75.749 1.892 0 2.641l-5.673 5.673c-.357.357-.821.535-1.32.535-.5 0-.964-.178-1.321-.535-.714-.713-.714-1.891 0-2.64l2.458-2.459H.005C.95 27.3 8.134 33.896 16.84 33.896c9.348 0 16.947-7.6 16.947-16.948S26.188 0 16.84 0" mask="url(#mb3rntpcmb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/36_01.jpeg`,
               link: `https://www.google.com/maps?q=%EA%B2%BD%EC%83%81%EB%82%A8%EB%8F%84+%ED%95%A8%EC%95%88%EA%B5%B0+%EA%B0%80%EC%95%BC%EC%9D%8D+%EB%8F%84%ED%95%AD%EB%A6%AC+341-4&ftid=0x356f2359dde34509:0x64c7f9b22d17a5e8&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 6,
               title: 37,
               subject: '관동교 서편',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-830 -671) translate(830 671)">
                        <path fill="#3C51B9" d="M16.948 0C7.6 0 0 7.6 0 16.948c0 2.364.489 4.614 1.367 6.66H9.99l.003-.001h13.136V15.28h-12.06l2.497 2.498c.714.714.714 1.89 0 2.64-.714.714-1.891.714-2.64 0l-5.638-5.673c-.749-.75-.749-1.891 0-2.64l5.674-5.672c.356-.358.82-.537 1.319-.537.5 0 .964.179 1.321.537.713.712.713 1.89 0 2.639l-2.498 2.498H24.965c.381 0 .715.144 1.008.342.493.332.834.867.834 1.514 0 .01-.007.02-.007.033v11.88c0 .53-.231 1-.591 1.335-.335.366-.806.603-1.342.603H9.993l-.008-.001H3.54c3.101 4.017 7.95 6.619 13.408 6.619 9.348 0 16.948-7.6 16.948-16.948S26.296 0 16.948 0" mask="url(#lte2jgs7hb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/37_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2737821,128.3962851&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 6,
               title: 38,
               subject: '가야교',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-830 -232) translate(830 232)">
                        <path fill="#3C51B9" d="M16.84 0C8.123 0 .93 6.613 0 15.087h20.189l-2.493-2.492c-.712-.714-.712-1.891 0-2.64.714-.714 1.892-.714 2.64 0l5.639 5.672c.749.75.749 1.892 0 2.641l-5.673 5.673c-.357.357-.821.535-1.32.535-.5 0-.964-.178-1.321-.535-.714-.713-.714-1.891 0-2.64l2.458-2.459H.005C.95 27.3 8.134 33.896 16.84 33.896c9.348 0 16.947-7.6 16.947-16.948S26.188 0 16.84 0" mask="url(#mb3rntpcmb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/38_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2724565,128.3923184&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
         ],
      },
      {
         title: 7,
         dist: 3.5,
         time: 65,
         rest: 0,
         tour: 0,
         start: '남문마을 배수장',
         end: '함안버스터미널',
         titleColor: '#db71a9',
         infoColor: '#db71a9',
         infoExpand: false,
         map: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/section/07.png`,
         info: [
            {
               section: 7,
               title: 39,
               subject: '남문마을',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-1153 -185) translate(1153 185)">
                        <path fill="#DB71A9" d="M17.008 0C7.615 0 0 7.614 0 17.008c0 9.393 7.615 17.008 17.008 17.008.71 0 1.407-.058 2.095-.142L11.39 25.8c-.348-.365-.546-.848-.553-1.353l-.28-18.79c-.011-.781.434-1.498 1.14-1.835.705-.338 1.543-.23 2.143.271l10.153 8.484c-.08-1.85-.151-3.554-.151-3.843 0-1.104.895-2 2-2 1.104 0 2 .896 2 2 .003.46.227 5.527.368 8.617.026.575-.197 1.133-.612 1.533-.374.359-.871.557-1.386.557-.057 0-.113-.002-.17-.006l-8.362-.715c-1.101-.095-1.917-1.063-1.822-2.163.094-1.101 1.066-1.919 2.163-1.824l2.578.221-5.977-4.994.203 13.644 8.388 8.782c.104.108.19.225.264.348 6.182-2.547 10.538-8.625 10.538-15.726C34.016 7.614 26.402 0 17.008 0" mask="url(#v7vv3fav7b)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/39_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2724707,128.3914541&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 7,
               title: 40,
               subject: '신음천(남문마을)',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-1154 -245) translate(1154 245)">
                        <path fill="#DB71A9" d="M16.84 0C8.121 0 .93 6.613 0 15.087H20.19l-2.492-2.491c-.713-.714-.713-1.892 0-2.641.713-.714 1.89-.714 2.64 0l5.637 5.673c.75.749.75 1.89 0 2.64l-5.672 5.673c-.357.357-.822.536-1.32.536-.5 0-.965-.18-1.322-.536-.712-.713-.712-1.891 0-2.64l2.46-2.458H.005C.95 27.3 8.133 33.896 16.84 33.896c9.349 0 16.948-7.6 16.948-16.948S26.188 0 16.841 0" mask="url(#bzfh3o73cb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/40_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2727904,128.3922001&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 7,
               title: 41,
               subject: '남문외 6호분',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g fill="#DB71A9">
                        <path d="M1170.948 338.896c8.718 0 15.91-6.612 16.84-15.087h-20.189l2.493 2.492c.713.714.713 1.891 0 2.641-.714.713-1.892.713-2.641 0l-5.637-5.674c-.749-.749-.749-1.891 0-2.64l5.673-5.672c.357-.358.82-.537 1.32-.537.499 0 .963.179 1.32.537.714.712.714 1.89 0 2.639l-2.458 2.458h20.114c-.945-8.457-8.128-15.053-16.835-15.053-9.348 0-16.948 7.6-16.948 16.949 0 9.347 7.6 16.947 16.948 16.947" transform="translate(-1154 -305)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/41_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2736491,128.3924716&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 7,
               title: 42,
               subject: '신음천(가야배수장)',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-1154 -359) translate(1154 359)">
                        <path fill="#DB71A9" d="M0 16.948c0 8.719 6.613 15.91 15.087 16.84v-20.19l-2.492 2.494c-.714.713-1.891.713-2.64 0-.714-.714-.714-1.891 0-2.641l5.673-5.637c.749-.75 1.89-.75 2.64 0l5.673 5.673c.357.357.536.82.536 1.32 0 .499-.18.963-.536 1.32-.713.714-1.891.714-2.64 0l-2.458-2.458v20.114c8.457-.945 15.053-8.128 15.053-16.835C33.896 7.6 26.296 0 16.948 0S0 7.6 0 16.948" mask="url(#sb1negvjbb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/42_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2763507,128.3945802&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 7,
               title: 43,
               subject: '남문외 1호분',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-1154 -413) translate(1154 413)">
                        <path fill="#DB71A9" d="M4.958 28.925c6.165 6.165 15.926 6.574 22.576 1.24L13.258 15.888v3.524c0 1.01-.832 1.842-1.867 1.867-1.01 0-1.841-.833-1.867-1.867l.026-7.997c0-1.059.807-1.867 1.866-1.867h8.023c.505 0 .959.201 1.312.555.353.353.555.807.555 1.312 0 1.01-.832 1.842-1.867 1.866h-3.476l14.223 14.223c5.312-6.648 4.896-16.392-1.26-22.548-6.611-6.61-17.358-6.61-23.968 0-6.61 6.611-6.61 17.358 0 23.968" mask="url(#ymsmks7rjb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/43_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2787240,128.3962418&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 7,
               title: 44,
               subject: '가야마을입구 주차장',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g fill="#DB71A9">
                        <path d="M1170.948 338.896c8.718 0 15.91-6.612 16.84-15.087h-20.189l2.493 2.492c.713.714.713 1.891 0 2.641-.714.713-1.892.713-2.641 0l-5.637-5.674c-.749-.749-.749-1.891 0-2.64l5.673-5.672c.357-.358.82-.537 1.32-.537.499 0 .963.179 1.32.537.714.712.714 1.89 0 2.639l-2.458 2.458h20.114c-.945-8.457-8.128-15.053-16.835-15.053-9.348 0-16.948 7.6-16.948 16.949 0 9.347 7.6 16.947 16.948 16.947" transform="translate(-1154 -305)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/44_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2788789,128.3954221&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 7,
               title: 45,
               subject: '연꽃테마파크 입구',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-1154 -245) translate(1154 245)">
                        <path fill="#DB71A9" d="M16.84 0C8.121 0 .93 6.613 0 15.087H20.19l-2.492-2.491c-.713-.714-.713-1.892 0-2.641.713-.714 1.89-.714 2.64 0l5.637 5.673c.75.749.75 1.89 0 2.64l-5.672 5.673c-.357.357-.822.536-1.32.536-.5 0-.965-.18-1.322-.536-.712-.713-.712-1.891 0-2.64l2.46-2.458H.005C.95 27.3 8.133 33.896 16.84 33.896c9.349 0 16.948-7.6 16.948-16.948S26.188 0 16.841 0" mask="url(#bzfh3o73cb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/45_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2794329,128.3970716&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 7,
               title: 46,
               subject: '연꽃테마파크 전망대',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g fill="#DB71A9">
                        <path d="M1170.948 338.896c8.718 0 15.91-6.612 16.84-15.087h-20.189l2.493 2.492c.713.714.713 1.891 0 2.641-.714.713-1.892.713-2.641 0l-5.637-5.674c-.749-.749-.749-1.891 0-2.64l5.673-5.672c.357-.358.82-.537 1.32-.537.499 0 .963.179 1.32.537.714.712.714 1.89 0 2.639l-2.458 2.458h20.114c-.945-8.457-8.128-15.053-16.835-15.053-9.348 0-16.948 7.6-16.948 16.949 0 9.347 7.6 16.947 16.948 16.947" transform="translate(-1154 -305)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/46_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2818950,128.3959816&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 7,
               title: 47,
               subject: '경남FC',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-1154 -245) translate(1154 245)">
                        <path fill="#DB71A9" d="M16.84 0C8.121 0 .93 6.613 0 15.087H20.19l-2.492-2.491c-.713-.714-.713-1.892 0-2.641.713-.714 1.89-.714 2.64 0l5.637 5.673c.75.749.75 1.89 0 2.64l-5.672 5.673c-.357.357-.822.536-1.32.536-.5 0-.965-.18-1.322-.536-.712-.713-.712-1.891 0-2.64l2.46-2.458H.005C.95 27.3 8.133 33.896 16.84 33.896c9.349 0 16.948-7.6 16.948-16.948S26.188 0 16.841 0" mask="url(#bzfh3o73cb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/47_01.jpeg`,
               link: `https://www.google.com/maps?q=%EA%B2%BD%EC%83%81%EB%82%A8%EB%8F%84+%ED%95%A8%EC%95%88%EA%B5%B0+%EA%B0%80%EC%95%BC%EC%9D%8D+%EB%8F%84%ED%95%AD%EB%A6%AC+261-2&ftid=0x356f235495a5ef27:0xdd2fc6b5de15f0ee&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 7,
               title: 48,
               subject: '공설운동장 정문',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-1153 -705) translate(1153 705)">
                        <path fill="#DB71A9" d="M33.768 14.121C32.61 7.077 26.928 1.395 19.884.239 8.254-1.675-1.676 8.254.237 19.884 1.395 26.93 7.077 32.61 14.122 33.77c.49.08.972.11 1.455.15v-7.591c-4.158-.296-7.75-3.396-8.154-7.587-.489-5.077 3.42-9.36 8.324-9.36H20.7V7.32c0-.655.71-1.066 1.28-.74l6.93 3.983c.4.23.4.81 0 1.04l-7.046 4.047c-.518.299-1.164-.075-1.164-.672V12.78h-4.674c-2.408 0-4.607 1.611-5.12 3.964-.713 3.268 1.74 6.238 4.977 6.238h1.62c.374 0 .691.194.897.473.35.311.577.753.577 1.256v9.155c9.37-1.09 16.43-9.78 14.791-19.745" mask="url(#yr5sy01p7b)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/48_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2821564,128.3991044&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 7,
               title: 49,
               subject: '함주공원 연못',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-1154 -413) translate(1154 413)">
                        <path fill="#DB71A9" d="M4.958 28.925c6.165 6.165 15.926 6.574 22.576 1.24L13.258 15.888v3.524c0 1.01-.832 1.842-1.867 1.867-1.01 0-1.841-.833-1.867-1.867l.026-7.997c0-1.059.807-1.867 1.866-1.867h8.023c.505 0 .959.201 1.312.555.353.353.555.807.555 1.312 0 1.01-.832 1.842-1.867 1.866h-3.476l14.223 14.223c5.312-6.648 4.896-16.392-1.26-22.548-6.611-6.61-17.358-6.61-23.968 0-6.61 6.611-6.61 17.358 0 23.968" mask="url(#ymsmks7rjb)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/49_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2820735,128.4005196&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 7,
               title: 50,
               subject: '함주공원 입구',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-1154 -824) translate(1154 824)">
                        <path fill="#DB71A9" d="M4.434 5.506c6.308-6.899 17.045-7.38 23.942-1.073 6.9 6.308 7.381 17.045 1.075 23.944-4.072 4.451-9.983 6.214-15.52 5.222.033-.132.082-.259.082-.4V22.38l8.237-8.91.159 3.528c.044 1.01.914 1.803 1.948 1.781 1.009-.044 1.803-.914 1.782-1.948l-.384-7.988c-.047-1.059-.89-1.829-1.948-1.78l-8.015.357c-.505.023-.95.244-1.287.613-.337.37-.518.831-.496 1.336.046 1.008.915 1.802 1.95 1.782l3.529-.159-8.653 9.36c-.382.418-.519.951-.458 1.466v10.73c-1.743-.732-3.398-1.75-4.872-3.099-6.898-6.307-7.38-17.045-1.071-23.943" mask="url(#2o2j9xnk7b)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/50_01.jpeg`,
               link: `https://www.google.com/maps?q=35.2816903,128.4025695&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 7,
               title: 51,
               subject: '함주교',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-1154 -882) translate(1154 882)">
                        <path fill="#DB71A9" d="M16.948 0C7.599 0 0 7.6 0 16.948c0 3.033.81 5.877 2.212 8.342l8.261-6.578v-5.465c-.017-.103-.061-.198-.061-.308 0-1.035.821-1.855 1.855-1.855h11.204l-2.498-2.497c-.713-.714-.713-1.892 0-2.641.714-.714 1.891-.714 2.641 0l5.637 5.673c.75.75.75 1.892 0 2.641l-5.673 5.673c-.357.356-.82.535-1.32.535-.499 0-.963-.179-1.321-.535-.713-.714-.713-1.891 0-2.641l2.498-2.497h-9.334v4.773c0 .316-.101.597-.243.856-.037.074-.092.137-.139.206-.074.099-.142.196-.234.278-.042.041-.064.095-.111.132l-9.035 7.194c3.104 3.465 7.598 5.662 12.609 5.662 9.348 0 16.947-7.6 16.947-16.948S26.296 0 16.948 0" mask="url(#phtu8vaa1b)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/51_01.jpeg`,
               link: `https://www.google.com/maps?q=%EA%B2%BD%EC%83%81%EB%82%A8%EB%8F%84+%ED%95%A8%EC%95%88%EA%B5%B0+%EA%B0%80%EC%95%BC%EC%9D%8D+%EB%8F%84%ED%95%AD%EB%A6%AC+130-3&ftid=0x356f24ab2722861f:0x6d345ed26976d600&hl=ko-KR&gl=kr&entry=gps&lucs=swa&shorturl=1`,
            },
            {
               section: 7,
               title: 52,
               subtitle: '도착',
               subject: '함안시외버스터미널',
               direction: (
                  <svg width="34" height="34" viewBox="0 0 34 34">
                     <g transform="translate(-1154 -943) translate(1154 943)">
                        <path fill="#DB71A9" d="M16.947 0C7.6 0 0 7.6 0 16.948c0 5.46 2.603 10.31 6.623 13.41v-6.425c0-.01-.006-.02-.006-.03V9.028c0-.536.238-1.008.603-1.343.335-.359.804-.59 1.334-.59h14.881c.012 0 .022-.006.034-.006.647 0 1.181.34 1.513.833.198.293.342.627.342 1.008v13.86l2.498-2.497c.75-.713 1.927-.713 2.641 0 .356.357.535.82.535 1.32 0 .5-.179.963-.535 1.32l-5.673 5.672c-.75.75-1.892.75-2.64 0l-5.674-5.637c-.714-.748-.714-1.925 0-2.64.75-.713 1.927-.713 2.64 0l2.498 2.498v-12.06H10.288v13.136c0 .004-.003.008-.003.013v8.612c2.046.878 4.298 1.369 6.662 1.369 9.35 0 16.948-7.6 16.948-16.95C33.895 7.602 26.296 0 16.947 0" mask="url(#qnuboqi29b)" />
                     </g>
                  </svg>
               ),
               image: `https://s3.ap-northeast-2.amazonaws.com/image.haman.guide.walks/guideline/roadSign/52_01.jpeg`,
               link: null,
            },
         ],
      },
   ]


   const datalength = data.length
   const dist = Number(data.map((d, ddex) => d.dist).reduce((x, y) => x + y) - 0.9).toFixed(1)
   const alltime = data.map((d, dex) => d.time + d.rest + d.tour).reduce((x, y) => x + y)
   const hour = Math.floor(alltime / 60)
   const min = alltime - (hour * 60)
   const all = {
      datalength, dist, alltime, hour, min
   }

   return {
      data, all
   }
}