import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useGuide from '../../hook/guide/useGuide';

const Guide = () => {
   const guide = useGuide()

   const [ list, setList ] = useState(guide.data)
   const parentsRef = useRef(null)
   const childRef = useRef(null)
   const [ clickCheck, setClickCheck ] = useState(null)

   useEffect(() => {
      if (clickCheck !== null && parentsRef.current !== null && childRef.current !== null) {
         const parents = parentsRef.current
         const child = childRef.current
         const listItem = document.querySelector(`.${clickCheck}`)
         const childRect = child.getBoundingClientRect()
         const listItemRect = listItem.getBoundingClientRect()
         let listHeight
         for (const iterator of child.children) {
            if (listItem !== iterator) listHeight = iterator.offsetHeight
         }
         parents.scrollTop = listItemRect.y - childRect.y - listHeight
         setClickCheck(null)
      }
   }, [clickCheck])

   const [ popup, setPopup ] = useState(false)
   const [ popupItems, setPopupItems ] = useState(null)
   const [ popupNextItems, setPopupNextItems ] = useState(null)

   return (
      <>
      <TITLE>구간안내</TITLE>
      <GUIDE ref={parentsRef}>
         <LIST ref={childRef}>
         {list.map((g, gdex) => 
            <li key={gdex}
               className={`guideList${gdex}`}
            >
               <div className={'up'}
                  onClick={event => {
                     setList(state => state.map((s, sdex) => (
                        s.title === g.title ? {
                           ...s,
                           infoExpand: !s.infoExpand,
                        } : {
                           ...s,
                           infoExpand: false,
                        }
                     )))
                     setClickCheck(`guideList${gdex}`)
                  }}
               >
                  <div className={'l'}>
                     <div>
                        <h3 style={{ color: g.titleColor }}>{g.title}구간</h3>&nbsp;
                        {g.dist !== 0 && g.time !== 0  && 
                        <p>
                           {`${g.dist}km / ${g.time}분${g.rest !== 0 ? '+휴식' + g.rest + '분' : ''}${g.tour !== 0 ? '+견학' + g.tour + '분' : ''}`}
                        </p>
                        }
                     </div>
                     <div>
                        {g.start !== '' && g.end !== '' ? 
                        <span>{g.start}에서 {g.end}까지</span> :
                        <p>
                           {`총 ${guide.all.datalength}구간 / ${guide.all.dist}km / 소요시간 ${guide.all.hour}시간 ${guide.all.min}분`}
                        </p>
                        }
                     </div>
                  </div>
                  <div className={'r'}>
                     <button>
                        <svg xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="14" viewBox="0 0 24 14">
                           <defs>
                              <path id="48ethdr03a" d="M0 0L24 0 24 13.44 0 13.44z" />
                           </defs>
                           <g fill="none" fillRule="evenodd">
                              <path d="M0 0H32V32H0z" transform="translate(-327 -174) translate(24 156) translate(299 9)" />
                              <g transform="translate(-327 -174) translate(24 156) translate(299 9) translate(4 9)">
                                 <mask id="karq092p2b" fill="#fff">
                                    <use xlinkHref="#48ethdr03a" />
                                 </mask>
                                 <path fill="#979797" d="M23.56.437c-.61-.558-1.557-.6-2.16 0l-1.046 1.04-2.496 2.481-3.035 3.017-2.61 2.595-.215.212-2.461-2.446-3.035-3.017-2.61-2.596C3.466 1.303 3.047.87 2.615.456L2.597.437C2.032-.125.994-.166.437.437c-.561.607-.603 1.548 0 2.148l1.046 1.04 2.496 2.481 3.035 3.017 2.61 2.595c.424.421.844.854 1.275 1.268l.02.019c.583.58 1.576.58 2.16 0l1.046-1.04 2.496-2.482 3.035-3.016 2.61-2.596c.424-.421.86-.839 1.275-1.267l.02-.02c.564-.56.606-1.593 0-2.147" mask="url(#karq092p2b)" />
                              </g>
                           </g>
                        </svg>
                     </button>
                  </div>
               </div>
               {g.infoExpand && g.info.length > 0 &&
               <ul className={'down'}>
               {g.map && <img className={'section'} src={g.map} alt={'section map'} />}
               {g.info.map((i, idex) => 
               i.title === '전체' ? 
                  <img key={idex} src={i.image} alt={i.title} /> :
                  <li key={idex}>
                     {i.direction}
                     <h4
                        onClick={event => {
                           const listinfo = list.map((l, ldex) => (
                              l.info.filter((f, fdex) => f.title === i.title + 1)
                           ))
                           const listinfoItem = listinfo.filter((l, ldex) => l.length > 0)
                           setPopup(true)
                           setPopupItems(i)
                           setPopupNextItems(
                              listinfoItem.length > 0 ? listinfoItem[0][0] : null
                           )
                        }}
                     >
                        <span>
                           {`${i.subtitle ? i.subtitle : i.title}.${i.subject}`}
                        </span>
                     </h4>
                     <a 
                        href={i.link} 
                        onClick={(event) => {
                           window.gtag('event', 'guide_map_link', {
                              'link': `${i.section}구간 ${i.title}.${i.subject}`
                           })
                        }}
                     >
                        <svg width="13" height="17" viewBox="0 0 13 17">
                           <defs>
                              <path id="a4qekuzhfa" d="M0 0L13 0 13 17 0 17z" />
                           </defs>
                           <g fill="none" fillRule="evenodd">
                              <g>
                                 <g transform="translate(-24 -320) translate(24 320)">
                                    <mask id="j62ez8xw6b" fill="#fff">
                                       <use xlinkHref="#a4qekuzhfa" />
                                    </mask>
                                    <path fill="#8ac569" d="M6.472 10.675C4.19 10.659 2.35 8.784 2.363 6.497c.017-2.29 1.884-4.14 4.165-4.125 2.28.015 4.123 1.89 4.107 4.179-.015 2.289-1.882 4.138-4.163 4.124M6.544 0C2.96-.025.025 2.883 0 6.48-.02 9.943 5.748 16.522 5.99 16.799c.11.129.271.201.442.201.167.002.327-.069.442-.195.25-.274 6.103-6.774 6.127-10.238C13.02 2.969 10.128.024 6.544 0" mask="url(#j62ez8xw6b)" />
                                 </g>
                              </g>
                           </g>
                        </svg>
                     </a>
                  </li>
               )}
               </ul>
               }
            </li>
         )}
         </LIST>
      </GUIDE>
      {popup && popupItems &&
      <POPUP 
         nextDirectionColor={list.map((l, ldex) =>
            l.infoColor
         )[popupItems.section]}
      >
         <div>
            <button className={'close'}
               onClick={event => {
                  setPopup(false)
                  setPopupItems(null)
                  setPopupNextItems(null)
               }}
            >
               X
            </button>
            <img className={'img'} src={popupItems.image ? popupItems.image : ''} alt={popupItems.subject} />
            <div className={'body'}>
               <div className={'title'}>
                  <h1>
                     { popupItems.subject }
                  </h1>
                  <div className={'label'}>
                     <span className={'section'}>
                        {`아라가야역사순례길 ${popupItems.section}구간`}
                     </span>
                     <span className={'turn'}>
                        {`${popupItems.title > 0 ? popupItems.title : 52}`}
                     </span>
                  </div>
                  <div className={'map'}>
                     <a 
                        href={popupItems.link}
                        onClick={event => {
                           window.gtag('event', 'guide_map_link', {
                              'popup': `${popupItems.section}구간 ${popupItems.title}.${popupItems.subject}`
                           })
                        }}
                     >
                        지도보기
                     </a>
                  </div>
               </div>
               <div className={'next'}>
                  {popupNextItems && 
                  <>
                     <span>다음방향</span>
                     { popupNextItems.direction }
                  </>
                  }
               </div>
            </div>
         </div>
      </POPUP>
      }
      </>
   );
};

export default React.memo(Guide);

const GUIDE = styled.section`
   display: flex; flex-direction: column;
   width: 100%; height: calc(100% - 60px - 85px);
   overflow: hidden scroll;
`
const TITLE = styled.h1`
   font-size: 32px; font-weight: 800;
   line-height: 1.6; color: #424242; 
   padding: 10px 24px;
   border-bottom: 1px solid #979797;
   &>p {
      font-size: 13px; font-weight: 500;
      color: #444444;
   }
`
const LIST = styled.ul`
   &>li {
      min-height: 68px;
      border-bottom: 1px solid #979797;
      .up {
         display: flex;
         padding: 10px 0px 10px 24px;
         .l {
            flex: 1;
            display: flex; flex-direction: column;
            justify-content: space-around;
            &>div {
               display: flex; align-items: flex-end;
               line-height: 1.6; color: #444444;
               &>h3 {
                  font-size: 21px; font-weight: 500;
               }
               &>p {
                  font-size: 12px; font-weight: 800;
               }
               &>span {
                  font-size: 16px; font-weight: 200;
                  letter-spacing: -1px;
               }
            }
         }
         .r {
            flex: 0 auto;
            display: flex; align-items: center;
            width: 70px; height: 50px;
            &>button {
               width: 100%; height: 100%;
            }
         }
      }
      .down {
         border-top: 1px solid #979797;
         background-color: #f7f7f7;
         &>.map {
            display: flex; justify-content: center; align-items: center;
            padding: 20px 0;
            border-bottom: 1px solid #979797;
         }
         li + li {
            border-top: 1px solid #e5e5e5;
         }
         &>li {
            display: flex; align-items: center;
            padding: 10px 24px;
            &>.start {
               display: flex; align-items: center; justify-content: center;
               width: 34px; height: 34px;
               font-size: 14px; color: #8ac569;
            }
            &>h4 {
               flex: 1;
               font-size: 18px; font-weight: 500;
               letter-spacing: -0.1px; line-height: 1.2;
               padding-left: 10px;
               &>span {
                  cursor: pointer;
               }
            }
            &>a {
               flex: 0 auto;
               display: flex; align-items: center; justify-content: flex-end;
               width: 30px; height: 100%;
               margin: 0 0 0 10px;
               cursor: pointer;
               &>svg {
                  width: 18px; height: 24px;
               }
            }
         }
         &>img {
            &.section {
               padding: 0; background-color: none;
            }
            width: 100%;
            padding: 40px 20px;
            margin: auto;
            background-color: #f1e7d8;
         }
      }
   }
`
const POPUP = styled.article`
   position: fixed; top: 0; left: 0; z-index: 1000;
   display: flex; align-items: center; justify-content: center;
   width: 100%; height: 100%;
   padding: 0 20px;
   background-color: rgba(27,27,27,0.8);
   &>div {
      position: relative;
      display: flex; flex-direction: column;
      width: 320px; max-width: 320px; min-height: 442px;
      border-bottom-left-radius: 13px; border-bottom-right-radius: 13px;
      background-color: #ffffff;
      &>.close {
         position: absolute; top: 13px; left: 10px;
         display: flex; align-items: center; justify-content: center;
         width: 21px; height: 21px;
         font-size: 12px; font-weight: 800; color: #ffffff;
         border-radius: 50%;
         background-color: #353535;
      }
      &>.img {
         flex: 0 auto;
         width: 100%; height: 233px;
         background-color: ${props => props.nextDirectionColor};
      }
      &>.body {
         flex: 1 auto;
         display: flex; flex-direction: column; 
         align-items: center; justify-content: space-between;
         padding: 25px 20px 20px 20px;
         &>.title {
            width: 100%;
            &>h1 {
               font-size: 18px; font-weight: 500; color: #444444;
               text-align: center;
               padding: 5px 0px;
            }
            &>.label {
               display: flex; justify-content: center; align-items: center;
               padding: 0 0 15px 0;
               &>.section {
                  font-size: 12px; font-weight: 800; color: #acacac;
                  padding: 0px 5px 0px 0px;
               }
               &>.turn {
                  display: flex; justify-content: center;
                  width: 17px; height: 17px;
                  font-size: 12px; font-weight: 800; color: #ffffff;
                  line-height: 1.3;
                  border-radius: 50%;
                  background-color: #663d0a;
               }
            }
            &>.map {
               width: 100%;
               &>a {
                  display: flex; justify-content: center; align-items: center;
                  width: 100%; height: 35px;
                  color: #ffffff; font-size: 12px;
                  border-radius: 9px;
                  background-color: ${props => props.nextDirectionColor};
               }
            }
         }
         &>.next {
            display: flex; justify-content: flex-end;
            width: 100%;
            &>span {
               font-size: 12px; color: #444444;
               line-height: 1.6;
               padding: 0px 5px;
            }
            &>svg path {
               fill: ${props => props.nextDirectionColor};
            }
         }
      }
   }
`