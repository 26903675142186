import { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom'
import { createGlobalStyle, css } from 'styled-components';
import Header from './component/header/Header';
import Main from './component/main/Main';
import Pages from './component/Pages';
import Recommend from './component/recommend/Recommend';

function App() {
   const location = useLocation()
   const [styles, setStyles] = useState(null)

   useEffect(() => {
      const index = ['/', '/index', '/index.html']
      if (index.includes(location.pathname)) {
         setStyles(css`
            background-color: #fafbf3;
         `)
      } else {
         setStyles(css`
            background-color: #ffffff;
         `)
      }
   }, [location])
  return (
     <>
      <GlobalStyles 
         styles={styles}
      />
      <Header />
      <Switch>
         <Route path={['/', '/index', '/index.html']} exact={true} component={Main} />
         <Route path={'/recommend'} component={Recommend} />
         <Route 
            render={(props) => (
               <Pages pathname={props.location.pathname} route={props} />
            )}
         />
      </Switch>
     </>
  );
}

export default App;

const GlobalStyles = createGlobalStyle`
   html, body {
      ${props => props.styles}
   }
`
