import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import Slide from '../main/Slide';

const RecommendItems = (props) => {
   const routeMatch = useRouteMatch()
   const { params } = routeMatch
   const { items } = props.recommend
   const item = items[params.id]

   return (
      <ITEMS onClick={event => event.stopPropagation()}>
         <h1>추천명소</h1>
         <div>
            <div>
               <article>
                  <Slide 
                     title={item.title}
                     slideImage={item.slideImage}
                  />
               </article>
               <h2>
               {item.title}{item.label !== '' && `(${item.label})`}
               </h2>
               <div className={'address'}>
                  <span>
                     <svg width="13" height="17" viewBox="0 0 13 17">
                        <defs>
                           <path id="a4qekuzhfa" d="M0 0L13 0 13 17 0 17z" />
                        </defs>
                        <g fill="none" fillRule="evenodd">
                           <g>
                              <g transform="translate(-24 -320) translate(24 320)">
                                 <mask id="j62ez8xw6b" fill="#fff">
                                    <use xlinkHref="#a4qekuzhfa" />
                                 </mask>
                                 <path fill="#43B4E7" d="M6.472 10.675C4.19 10.659 2.35 8.784 2.363 6.497c.017-2.29 1.884-4.14 4.165-4.125 2.28.015 4.123 1.89 4.107 4.179-.015 2.289-1.882 4.138-4.163 4.124M6.544 0C2.96-.025.025 2.883 0 6.48-.02 9.943 5.748 16.522 5.99 16.799c.11.129.271.201.442.201.167.002.327-.069.442-.195.25-.274 6.103-6.774 6.127-10.238C13.02 2.969 10.128.024 6.544 0" mask="url(#j62ez8xw6b)" />
                              </g>
                           </g>
                        </g>
                     </svg>
                  </span>
                  &nbsp;{item.address}
               </div>
               <p>
                  {item.explain}
               </p>
               <div className={'tag'}>
               {item.tag.map((t, tdex) => 
                  <span key={tdex} style={t.style}>
                     #&nbsp;{ t.name }
                  </span>
               )}
               </div>
            </div>
            <div className={'recmdbgc'}>
               {item.bg}
            </div>
         </div>
      </ITEMS>
   );
};

export default React.memo(RecommendItems);

const ITEMS = styled.article`
   position: fixed; top: 0; left: 0;
   width: 100%; height: 100%;
   background-color: #fbfbfb;
   padding: 66px 0 0 0;
   &>h1 {
      position: absolute; top:0;
      display: flex; align-items: flex-end; justify-content: center;
      width: 100%; height: 66px;
      font-size: 21px; font-weight: 400; color: #424242;
      padding: 16px;
   }
   &>div {
      display: flex; flex-direction: column; justify-content: space-between;
      width: 100%; height: 100%;
      overflow: scroll;
      &>div {
         &>article {
            position: relative;
            width: 100%; height: 200px;
            .swiper-container {
               width: 100%; height: 100%;
               overflow: auto;
            }
            .swiper-slide {
               text-align: center;
               background: #ffffff;
               &>div {
                  width: 100%; height: 176px;
               }
            }
            .swiper-pagination {
               bottom: 0px;
               &>span {
                  margin: 0 10px;
                  &.swiper-pagination-bullet-active {
                     background-color: #000;
                  }
               }
            }
         }
         &>h2 {
            font-size: 24px; font-weight: 300; color: #444444;
            letter-spacing: -1.12px;
            padding: 20px 24px 5px 24px;
         }
         &>.address {
            display: flex; align-items: center;
            font-size: 14px; font-weight: 800; color: #43b4e7;
            padding: 8px 24px;
         }
         &>p {
            font-size: 13px; color: #444444;
            text-align: justify; line-height: 21px;
            padding: 5px 24px;
         }
         &>.tag {
            padding: 13px 24px;
            &>span {
               font-size: 14px;
               padding: 5px 10px;
               border-radius: 14px;
            }
         }
      }
      &>.recmdbgc {
         display: flex; justify-content: flex-end;
         padding: 24px;
      }
   }
`