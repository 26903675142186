import React from 'react';
import styled from 'styled-components'
import Guide from './guide/Guide';
import Introduce from './introduce/Introduce';

const Pages = (props) => {
   const { pathname } = props

   if (pathname === '/introduce') {
      return (
         <Introduce />
      )
   }
   if (pathname === '/guide') {
      return (
         <Guide />
      )
   }
   return (
      <PAGE>
         <h1>{pathname}</h1>
      </PAGE>
   );
};

export default React.memo(Pages);

const PAGE = styled.div`
   display: flex; flex-direction: column; align-items: center;
   width: 100%; height: calc(100% - 60px);
   &>h1 {
      font-size: 30px;
   }
`

