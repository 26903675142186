import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components'

const AddHome = () => {
   const [ first, setFirst ] = useState(true)
   const buttonRef = useRef(null)
   const deferredPrompt = useRef(null)

   const buttonClick = () => {
      setFirst(null)
      buttonRef.current.style.top = `-9999px`
      if (deferredPrompt.current !== null) {
         deferredPrompt.current.prompt()
         deferredPrompt.current.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
               console.log('accepted')
            } else {
               console.log('dismissed')
            }
            deferredPrompt.current = null
         })
      }
   }

   useEffect(() => {
      if (buttonRef.current === null) {
         return
      }
      const beforeInstallPrompt = (e) => {
         e.preventDefault()
         deferredPrompt.current = e
         buttonRef.current.style.top = `15px`
         buttonRef.current.addEventListener('click', buttonClick)
         return () => buttonRef.current = ''
      }
      if (first) {
         window.addEventListener('beforeinstallprompt', beforeInstallPrompt)
      } else {
         window.removeEventListener('beforeinstallprompt', beforeInstallPrompt)
      }
   }, [first])

   return (
      <BUTTON ref={buttonRef}>
         Install for Android
      </BUTTON>
   );
};

export default AddHome;

const BUTTON = styled.button`
   position: fixed; top: -9999px; right: 60px; z-index: 99;
   font-size: 10px; color: #ffffff;
   border-radius: 8px;
   padding: 5px 10px;
   background-color: #686868;
   transition: all 1s;
`